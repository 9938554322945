import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchInquiryReq } from '../store/action';
import { useContextex } from '../context/useContext';
import { useTranslation } from 'react-i18next';
import { getData, getInquiryPro, getLoading, getSecoundData, getSendEnquiryData } from '../store/selector';
import { CustomSnackbar } from '../utils/CustomSnackbar';

function InquiryPage({ setSendEReq }) {

    const [enquryData, setEnquryData] = useState(null)
    const [showSnackbar, setShowSnackbar] = useState(false);

    const { productDetailId, isUserId, bookedProductData } = useContextex();
    const dispatch = useDispatch();

    const data = useSelector(getInquiryPro)
    const loading  = useSelector(getLoading);
    const { t } = useTranslation();

    const handleSendRequest = useCallback(async (event) => {
        event.preventDefault();

        const formData = {
            uid: isUserId,
            prop_id: productDetailId,
        };

        try {
            await dispatch(fetchInquiryReq(formData));
            setSendEReq(true)
            setShowSnackbar(true)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
    }, [isUserId, productDetailId, dispatch]);

    useEffect(() => {
        if (data) {
            setEnquryData(data);
        }
    },[data])

    console.log('enquryData',enquryData)

    const isEnquiry = bookedProductData?.propetydetails?.is_enquiry;
    const isButtonDisabled = isEnquiry === 1;
    const buttonText = isEnquiry === 0
        ? `${t('Contact a Property Owner')} <i className="icon-arrow-right-add"></i>`
        : `${t('Contacted')}`;
    const buttonClass = `tf-button-primary w-full justify-content-center d-flex ${isEnquiry === 0 ? 'style-bg-white' : ''}`;

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };


    return (
        <>
            <div className="schedule">
                <h4 className="wow fadeInUp justify-content-center d-flex">{t('Inquiry Status')}</h4>
                <div className="button-submit mt-5">
                    <button                        
                        className={buttonClass}
                        onClick={handleSendRequest}
                        dangerouslySetInnerHTML={{ __html: buttonText }}
                    />
                </div>
            </div>
            {loading && (
                <div className="preload preload-container">
                    <div className="middle"></div>
                </div>
            )}
             {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={enquryData?.ResponseMsg} />}
        </>
    );
}

export default InquiryPage;
