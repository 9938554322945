import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useContextex } from '../context/useContext';
import Footer from '../partials/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchPackageData, packagePlan, packagePlanBuy } from '../store/action';
import { ReModal } from '../partials/ReModal';
import { KhaltiPayment } from '../payment/KhaltiPayment';
import { FlutterwavePayment } from '../payment/FlutterwavePayment';
import { PaystackPayment } from '../payment/PaystackPayment';
import { RazorpayPayment } from '../payment/RazorpayPayment';
import { PaytmPayment } from '../payment/PaytmPayment';
import SenangpayPayment from '../payment/SenangpayPayment';
import { MidtransPayment } from '../payment/MidtransPayment';
import { AddToProductPay } from '../payment/AddToWallet';
import { TwoCheckoutPayment } from '../payment/TwoCheckoutPayment';
import PayfastPayment from '../payment/PayfastPayment';
import { MercadoPagoPayment } from '../payment/MercadoPagoPayment';
import { useTranslation } from 'react-i18next';
import { getData, getPackData } from '../store/selector';
import { IconWallet } from '@tabler/icons-react';
import { uid } from 'uid';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { StripePayment } from '../payment/StripePayment';
import { PaypalPayment } from '../payment/PaypalPayment';

function PackagePurchase() {
    const [packageData, setPackageData] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false)
    const [paymentTrigger, setPaymentTrigger] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [walletPay, setWalletPay] = useState(false)
    const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);

    const { t } = useTranslation();
    const {
        setCurrentPage,
        isUserId,
        userCurrency,
        selectedPackage,
        setSelectedPackage,
        paymentGatwayList,
        imageBaseUrl,
        setSelectedPaymentId,
        selectedPackData,
        setSelectedPackData,
        selectedPaymentId,
        setSelectedPaymentType,
        selectedPaymentType,
        userWalletAmount,
        loginUserData,
        packageAmount,
        setPackageAmount,
        setTransactionId,
        transactionId
    } = useContextex();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(getData);
    const packData = useSelector(getPackData);

    useEffect(() => {
        const fetchPackagePlans = async () => {
            try {
                await dispatch(packagePlan({ uid: isUserId }));
            } catch (error) {
                console.error("Error fetching package plans:", error);
            }
        };

        fetchPackagePlans();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (data?.PackageData) {
            setPackageData(data.PackageData);
        }
    }, [data]);

    useEffect(() => {
        if ((selectedPaymentType == 'Stripe' || selectedPaymentType == 'Paypal' || selectedPaymentType == 'PayStack') && paymentTrigger) {
            setIsOpenPaymentModal(true);
            setIsOpenModal(false)
        }
    }, [selectedPaymentType, paymentTrigger]);

    useEffect(() => {
        setCurrentPage('package_purchase');
    }, [setCurrentPage]);

    const closeModal = () => {
        setIsOpenModal(false)
        setIsOpenPaymentModal(false)
        setPaymentTrigger(false)
    };

    const walletBalance = localStorage.getItem('avblWalletBlnc')
    // const walletBalance = userWalletAmount ? userWalletAmount : loginUserData?.UserLogin?.wallet
    const packPrice = selectedPackData?.price
    const paybleWalletAmnt = isChecked ? Math.min(walletBalance, packPrice) : '0';
    const filteredPaymentWay = paymentGatwayList?.filter(item => item?.s_show === '1' && item?.p_show === '1' && item?.id !== '2')
    const newFilteredData = filteredPaymentWay?.filter(item => !['4', '8', '10', '11', '13', '14', '15'].includes(item.id));


    useEffect(() => {
        const updatePackageAmount = async () => {
            const updatedPackageAmount = {
                packageWalletAmount: paybleWalletAmnt || '0',
                packageTotalAmnt: packPrice,
            };

            try {
                // Update state with new package amounts
                setPackageAmount(prevState => ({
                    ...prevState,
                    ...updatedPackageAmount,
                }));

                // Save to localStorage
                await localStorage.setItem('packageAmnt', JSON.stringify(updatedPackageAmount));
                await localStorage.setItem('packId', selectedPackData?.id);
                await localStorage.setItem('paymentName', selectedPackData?.price === 0 ? 'Trial' : selectedPaymentType);
            } catch (error) {
                console.error('Error updating package amount:', error);
            }
        };

        updatePackageAmount();
    }, [paybleWalletAmnt, packPrice, selectedPackData, selectedPaymentType]);

    const purchasePackage = useCallback(async () => {
        try {
            // Only call purchasePackage if selectedPaymentType is 'wallet' or selectedPackData?.price is 0
            if (selectedPaymentType == 'wallet' || selectedPackData?.price == 0) {
                await dispatch(packagePlanBuy({
                    uid: isUserId,
                    transaction_id: selectedPaymentType === 'wallet' || selectedPackData?.price === 0 ? uid(6) : 0,
                    plan_id: selectedPackData?.id,
                    pname: selectedPackData?.price === 0 ? 'Trial' : selectedPaymentType,
                    wall_amt: paybleWalletAmnt
                }));
                setShowSnackbar(true);
            }
        } catch (error) {
            console.error('Error purchasing package:', error);
        } finally {
            setIsChecked(false);
        }
    }, [selectedPaymentType, selectedPackData, isUserId, paybleWalletAmnt]);
    
    let finalPrice;
    
    if (packPrice && paybleWalletAmnt) {
        if (packPrice < paybleWalletAmnt) {
            finalPrice = paybleWalletAmnt - packPrice;
        } else if (isChecked) {
            finalPrice = packPrice;
            setSelectedPaymentType('wallet');
        }
    } else {
        finalPrice = packPrice;
    }
    
    const handleClick = (data) => {
        setSelectedPackage(data.id);
        setSelectedPackData(data);
        if (data.price > 0) {
            setIsOpenModal(true);
        } else {
            // Call purchasePackage directly only if price is 0
            purchasePackage();
        }
    };
    
    const handleToBook = useCallback(async () => {
        const isWalletPayment = isChecked && packPrice == paybleWalletAmnt;
    
        if (isWalletPayment && isChecked) {
            // Set payment type to wallet and proceed with wallet payment
            setSelectedPaymentType('wallet');
            setSelectedPaymentId('5');
            setWalletPay(true);
            
            // Only call purchasePackage if selectedPaymentType is wallet or price is 0
            await purchasePackage();
            return;
        }
    
        setWalletPay(false);
        setPaymentTrigger(!paymentTrigger);
        
        // Call purchasePackage for non-wallet payments if necessary
        if (selectedPaymentType !== 'wallet' && selectedPackData?.price !== 0) {
            await purchasePackage();
        }
    }, [isChecked, packPrice, paybleWalletAmnt, purchasePackage, selectedPaymentType, selectedPackData, paymentTrigger]);
    
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            <div className='main-content px-20 default'>
                <section className="tf-section flat-pricing">
                    <div className="cl-container">

                        <div className="row">
                            <div className="col-12">
                                <div className="heading-section text-center">
                                    <h2 className="wow fadeInUp">{t('Choose the right pricing plan for you')}</h2>
                                    <div className="text wow fadeInUp">
                                        {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            {packageData?.map((item) => (
                                <div className="col-xxl-3 col-md-6" key={item.id} onClick={() => handleClick(item)}>
                                    <div className={`pricing-item wow fadeInUp ${selectedPackage === item.id ? 'active' : ''}`} data-wow-delay="0.2s">
                                        <div className="top">
                                            <h4>{item.title}</h4>
                                            <h4>{userCurrency}{item.price}/<span>({item.day} {t('day')})</span></h4>
                                        </div>

                                        <div className="center">
                                            <ul>
                                                <li className="check-ellipse-item">
                                                    <div className="icon">
                                                        <i className="flaticon-check"></i>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                                </li>
                                            </ul>
                                            <a className="tf-button-primary w-full justify-content-center style-bg-white"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleClick(item);
                                                }}
                                            >
                                                {t('Continue')}
                                                <i className="icon-arrow-right-add"></i>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </section>
            </div>
            <Footer />

            {isOpenModal && selectedPackData && selectedPackData.price > 0 && (
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className='p-5 w-100'>
                        <section>
                            <div>
                                <div className='w-100'>
                                    <h4 className="neutral-top pb-5">{t('Select Payment Method')}</h4>
                                    <div className='d-flex row gap-5 align-items-center justify-content-between'>

                                        {walletBalance > 0 && <div className='d-flex flex-row w-100 col-10'>
                                            <form className='w-100'>
                                                <ul className="ft-download  bg-light  " style={{ borderRadius: '15px' }} >
                                                    <li style={{ width: 'auto' }}>
                                                        <a className='col-12 w-100 d-flex'>
                                                            <div className='col-10 d-flex align-items-center'>
                                                                <div className="icon text-dark mx-2">
                                                                    {/* Assuming IconWallet is an imported component */}
                                                                    <IconWallet />
                                                                </div>
                                                                <div className="app">
                                                                    <div className='text-dark'>{t('Your Balance')} {userCurrency}{walletBalance} </div>
                                                                    <div className='text-dark'>{t('Available for Payment')}</div>
                                                                </div>
                                                            </div>
                                                            <p className="switch-item col-2">
                                                                <label>
                                                                    <input
                                                                        className="check border"
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        name="check"
                                                                        checked={isChecked}
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                </label>
                                                            </p>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </form>
                                        </div>}

                                        {newFilteredData?.map((item) => (
                                            <div key={item?.id} onClick={() => { setSelectedPaymentType(item?.title); setSelectedPaymentId(item?.id) }} className={`categories-item col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 wow fadeInUp ${selectedPaymentId === item?.id ? 'active-map' : ''}`} >
                                                <div className="icon">
                                                    <img src={`${imageBaseUrl}${item.img}`} alt={`${item.title} icon`} />
                                                </div>
                                                <div>
                                                    <div className="name">
                                                        <a>{item.title}</a>
                                                    </div>
                                                    <div className="text">{item.subtitle}</div>
                                                </div>
                                            </div>
                                        ))}

                                        <div className="button-submit mt-10">
                                            <button className="tf-button-primary" onClick={handleToBook}>{t('Continue')}<i className="icon-arrow-right-add"></i></button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </ReModal>
            )}

            {showSnackbar && (
                <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={packData?.ResponseMsg} />
            )}

            {selectedPaymentType === 'Paytm' && paymentTrigger && <PaytmPayment product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'SenangPay' && paymentTrigger && <SenangpayPayment product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'Midtrans' && paymentTrigger && <MidtransPayment product_amount={selectedPackData?.price} />}
            {selectedPaymentType === '2checkout' && paymentTrigger && <TwoCheckoutPayment product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'PayStack' && paymentTrigger &&
                <div onClick={() => setPaymentTrigger(false)} className="PayStack">
                    <div onClick={(e) => e.stopPropagation()} className="PayStack2">
                        <PaystackPayment product_amount={selectedPackData?.price} booked_for={'package'} />
                    </div>
                </div>
            }
            {/* {selectedPaymentType === 'PayStack' && paymentTrigger && <PaystackPayment product_amount={selectedPackData?.price} booked_for={'package'} />} */}
            {selectedPaymentType === 'Razorpay' && paymentTrigger && <RazorpayPayment product_amount={selectedPackData?.price} booked_for={'package'} setPaymentTrigger={setPaymentTrigger} />}
            {selectedPaymentType === 'Khalti Payment' && paymentTrigger && <KhaltiPayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} booked_for={'package'} />}
            {selectedPaymentType === 'MercadoPago' && paymentTrigger && <MercadoPagoPayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} />}
            {selectedPaymentType === 'Payfast' && paymentTrigger && <PayfastPayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} booked_for={'package'} />}
            {selectedPaymentType === 'FlutterWave' && paymentTrigger && <FlutterwavePayment product_name={selectedPackData?.title} product_amount={selectedPackData?.price} product_id={selectedPackData.id} booked_for={'package'} />}

            {isOpenPaymentModal && selectedPaymentType === 'Stripe' && paymentTrigger &&
                <ReModal isOpenPaymentModal={isOpenPaymentModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/def/payment.jpg" alt="" />
                        <h3>{t('Complete Your Transaction')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex  flex-column'>
                            <StripePayment />
                        </form>
                    </div>
                </ReModal>
            }

            {selectedPaymentType === 'PayStack' && paymentTrigger &&
                <div onClick={() => setPaymentTrigger(false)} className="PayStack">
                    <div onClick={(e) => e.stopPropagation()} className="PayStack2">
                        <PaystackPayment product_amount={selectedPackData?.price} booked_for={'package'} />
                    </div>
                </div>
            }

            {
                isOpenPaymentModal && selectedPaymentType === 'Paypal' && paymentTrigger &&
                <>
                    <ReModal isOpenPaymentModal={isOpenPaymentModal} onClose={closeModal}>
                        <div className="image-left">
                            <img src="./../assets/images/def/payment.jpg" alt="" />
                            <h3>{t('Complete Your Transaction')}</h3>
                        </div>
                        <div className="content-right p-5 ">
                            <form className='form-bacsic-infomation flex align-items-center justify-content-center d-flex flex-column'>
                                <PaypalPayment product_amount={selectedPackData?.price} booked_for={'package'} />
                            </form>
                        </div>
                    </ReModal>
                </>
            }
        </>
    );
}

export default PackagePurchase;
