import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { AppProvider } from './context/AppContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create the theme
const theme = createTheme();

// Create a root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AppProvider>
    </Provider>
  </React.StrictMode>
);

// Measure performance (optional)
reportWebVitals();
