
export const FETCH_COUNTRY_DATA_REQUEST = 'FETCH_COUNTRY_DATA_REQUEST';
export const FETCH_COUNTRY_DATA_SUCCESS = 'FETCH_COUNTRY_DATA_SUCCESS';
export const FETCH_COUNTRY_DATA_FAILURE = 'FETCH_COUNTRY_DATA_FAILURE';

export const USER_HOME_DATA_REQUEST = 'USER_HOME_DATA_REQUEST';
export const USER_HOME_DATA_SUCCESS = 'USER_HOME_DATA_SUCCESS';
export const USER_HOME_DATA_FAILURE = 'USER_HOME_DATA_FAILURE';

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const SEARCH_DATA_REQUEST = 'SEARCH_DATA_REQUEST'; 
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS'; 
export const SEARCH_DATA_FAILURE = 'SEARCH_DATA_FAILURE'; 

export const FETCH_TO_PROPERTY_DETAILS_SUCCESS = 'FETCH_TO_PROPERTY_DETAILS_SUCCESS';
export const FETCH_TO_PROPERTY_DETAILS_REQUEST = 'FETCH_TO_PROPERTY_DETAILS_REQUEST';
export const FETCH_TO_PROPERTY_DETAILS_FAILURE = 'FETCH_TO_PROPERTY_DETAILS_FAILURE';

export const ADD_REMOVE_TO_FAVORITE_REQUEST = 'ADD_REMOVE_TO_FAVORITE_REQUEST';
export const ADD_REMOVE_TO_FAVORITE_SUCCESS = 'ADD_REMOVE_TO_FAVORITE_SUCCESS';
export const ADD_REMOVE_TO_FAVORITE_FAILURE = 'ADD_REMOVE_TO_FAVORITE_FAILURE';

export const FAV_PROD_LIST_REQUEST= 'FAV_PROD_LIST_REQUEST';
export const FAV_PROD_LIST_SUCCESS= 'FAV_PROD_LIST_SUCCESS';
export const FAV_PROD_LIST_FAILURE= 'FAV_PROD_LIST_FAILURE';

export const FAV_PROD_GALLARY_REQUEST= 'FAV_PROD_GALLARY_REQUEST';
export const FAV_PROD_GALLARY_SUCCESS= 'FAV_PROD_GALLARY_SUCCESS';
export const FAV_PROD_GALLARY_FAILURE= 'FAV_PROD_GALLARY_FAILURE';

export const FETCH_LOGIN_USER_SUCCESS = 'FETCH_LOGIN_USER_SUCCESS';
export const FETCH_LOGIN_USER_REQUEST = 'FETCH_LOGIN_USER_REQUEST';
export const FETCH_LOGIN_USER_FAILURE = 'FETCH_LOGIN_USER_FAILURE';

export const FETCH_LOGOUT_USER = 'FETCH_LOGOUT_USER';

export const FETCH_REGISTER_USER_SUCCESS = 'FETCH_REGISTER_USER_SUCCESS';
export const FETCH_REGISTER_USER_REQUEST = 'FETCH_REGISTER_USER_REQUEST';
export const FETCH_REGISTER_USER_FAILURE = 'FETCH_REGISTER_USER_FAILURE';

export const FETCH_OTP_TYPE_REQUEST = 'FETCH_OTP_TYPE_REQUEST';
export const FETCH_OTP_TYPE_SUCCESS = 'FETCH_OTP_TYPE_SUCCESS';
export const FETCH_OTP_TYPE_FAILURE = 'FETCH_OTP_TYPE_FAILURE';

export const FETCH_TWILLIO_OTP_REQUEST = 'FETCH_TWILLIO_OTP_REQUEST';
export const FETCH_TWILLIO_OTP_SUCCESS = 'FETCH_TWILLIO_OTP_SUCCESS';
export const FETCH_TWILLIO_OTP_FAILURE = 'FETCH_TWILLIO_OTP_FAILURE';

export const FETCH_MSG91_OTP_REQUEST = 'FETCH_MSG91_OTP_REQUEST';
export const FETCH_MSG91_OTP_SUCCESS = 'FETCH_MSG91_OTP_SUCCESS';
export const FETCH_MSG91_OTP_FAILURE = 'FETCH_MSG91_OTP_FAILURE';

export const CHECK_MOBILE_NUMBER_REQUEST = 'CHECK_MOBILE_NUMBER_REQUEST';
export const CHECK_MOBILE_NUMBER_SUCCESS = 'CHECK_MOBILE_NUMBER_SUCCESS';
export const CHECK_MOBILE_NUMBER_FAILURE = 'CHECK_MOBILE_NUMBER_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const FETCH_TO_WALLET_AMOUNT_SUCCESS = 'FETCH_TO_WALLET_AMOUNT_SUCCESS';
export const FETCH_TO_WALLET_AMOUNT_REQUEST = 'FETCH_TO_WALLET_AMOUNT_REQUEST';
export const FETCH_TO_WALLET_AMOUNT_FAILURE = 'FETCH_TO_WALLET_AMOUNT_FAILURE';

export const FETCH_WALLET_UPDATE_SUCCESS = 'FETCH_WALLET_UPDATE_SUCCESS';
export const FETCH_WALLET_UPDATE_REQUEST = 'FETCH_WALLET_UPDATE_REQUEST';
export const FETCH_WALLET_UPDATE_FAILURE = 'FETCH_WALLET_UPDATE_FAILURE';

export const FETCH_SECOUND_DATA_REQUEST = 'FETCH_SECOUND_DATA_REQUEST';
export const FETCH_SECOUND_DATA_SUCCESS = 'FETCH_SECOUND_DATA_SUCCESS';
export const FETCH_SECOUND_DATA_FAILURE = 'FETCH_SECOUND_DATA_FAILURE';

export const FETCH_SUBSCRIPE_PACKAGE_REQUEST= 'FETCH_SUBSCRIPE_PACKAGE_REQUEST'; 
export const FETCH_SUBSCRIPE_PACKAGE_SUCCESS = 'FETCH_SUBSCRIPE_PACKAGE_SUCCESS'; 
export const FETCH_SUBSCRIPE_PACKAGE_FAILURE = 'FETCH_SUBSCRIPE_PACKAGE_FAILURE'; 

export const FETCH_USER_DASHBOARD_DATA_SUCCESS = 'FETCH_USER_DASHBOARD_DATA_SUCCESS';
export const FETCH_USER_DASHBOARD_DATA_REQUEST = 'FETCH_USER_DASHBOARD_DATA_REQUEST';
export const FETCH_USER_DASHBOARD_DATA_FAILURE = 'FETCH_USER_DASHBOARD_DATA_FAILURE';

export const FETCH_ADD_PROPERTY_REQUEST = 'FETCH_ADD_PROPERTY_REQUEST';
export const FETCH_ADD_PROPERTY_SUCCESS = 'FETCH_ADD_PROPERTY_SUCCESS';
export const FETCH_ADD_PROPERTY_FAILURE = 'FETCH_ADD_PROPERTY_FAILURE';

export const FETCH_USER_GALLARY_CAT_REQUEST = 'FETCH_USER_GALLARY_CAT_REQUEST';
export const FETCH_USER_GALLARY_CAT_SUCCESS = 'FETCH_USER_GALLARY_CAT_SUCCESS';
export const FETCH_USER_GALLARY_CAT_FAILURE = 'FETCH_USER_GALLARY_CAT_FAILURE';

export const FETCH_USER_PROPERTY_REQUEST = 'FETCH_USER_PROPERTY_REQUEST';
export const FETCH_USER_PROPERTY_SUCCESS = 'FETCH_USER_PROPERTY_SUCCESS';
export const FETCH_USER_PROPERTY_FAILURE = 'FETCH_USER_PROPERTY_FAILURE';

export const FETCH_PROP_FACILITY_REQUEST = 'FETCH_PROP_FACILITY_REQUEST';
export const FETCH_PROP_FACILITY_SUCCESS = 'FETCH_PROP_FACILITY_SUCCESS';
export const FETCH_PROP_FACILITY_FAILURE = 'FETCH_PROP_FACILITY_FAILURE';

export const FETCH_PROP_WISE_GALCAT_REQUEST = 'FETCH_PROP_WISE_GALCAT_REQUEST';
export const FETCH_PROP_WISE_GALCAT_SUCCESS = 'FETCH_PROP_WISE_GALCAT_SUCCESS';
export const FETCH_PROP_WISE_GALCAT_FAILURE = 'FETCH_PROP_WISE_GALCAT_FAILURE';

export const PAYOUT_LIST_REQUEST = 'PAYOUT_LIST_REQUEST';
export const PAYOUT_LIST_SUCCESS = 'PAYOUT_LIST_SUCCESS';
export const PAYOUT_LIST_FAILURE = 'PAYOUT_LIST_FAILURE';

export const PAYOUT_REQUEST = 'PAYOUT_REQUEST';
export const PAYOUT_SUCCESS = 'PAYOUT_SUCCESS';
export const PAYOUT_FAILURE = 'PAYOUT_FAILURE';

export const FETCH_USER_CHECK_OUT_REQUEST = 'FETCH_USER_CHECK_OUT__REQUEST';
export const FETCH_USER_CHECK_OUT_SUCCESS = 'FETCH_USER_CHECK_OUT__SUCCESS';
export const FETCH_USER_CHECK_OUT_FAILURE = 'FETCH_USER_CHECK_OUT__FAILURE';

export const FETCH_USER_CONFIRM_PROP_REQUEST = 'FETCH_USER_CONFIRM_PROP_REQUEST';
export const FETCH_USER_CONFIRM_PROP_SUCCESS = 'FETCH_USER_CONFIRM_PROP_SUCCESS';
export const FETCH_USER_CONFIRM_PROP_FAILURE = 'FETCH_USER_CONFIRM_PROP_FAILURE';

export const FETCH_USER_CHECK_IN_REQUEST = 'FETCH_USER_CHECK_IN__REQUEST';
export const FETCH_USER_CHECK_IN_SUCCESS = 'FETCH_USER_CHECK_IN__SUCCESS';
export const FETCH_USER_CHECK_IN_FAILURE = 'FETCH_USER_CHECK_IN__FAILURE';

export const FETCH_REVIEW_LIST_REQUEST = 'FETCH_REVIEW_LIST_REQUEST'; 
export const FETCH_REVIEW_LIST_SUCCESS = 'FETCH_REVIEW_LIST_SUCCESS'; 
export const FETCH_REVIEW_LIST_FAILURE = 'FETCH_REVIEW_LIST_FAILURE'; 

export const FETCH_AVAILABLE_DATE_REQUEST = 'FETCH_AVAILABLE_DATE_REQUEST';
export const FETCH_AVAILABLE_DATE_SUCCESS = 'FETCH_AVAILABLE_DATE_SUCCESS';
export const FETCH_AVAILABLE_DATE_FAILURE = 'FETCH_AVAILABLE_DATE_FAILURE';

export const FETCH_REFER_USER_REQUEST = 'FETCH_REFER_USER_REQUEST';
export const FETCH_REFER_USER_SUCCESS = 'FETCH_REFER_USER_SUCCESS';
export const FETCH_REFER_USER_FAILURE = 'FETCH_REFER_USER_FAILURE';

export const FETCH_EDIT_PROFILE_USER_REQUEST = 'FETCH_EDIT_PROFILE_USER_REQUEST';
export const FETCH_EDIT_PROFILE_USER_SUCCESS = 'FETCH_EDIT_PROFILE_USER_SUCCESS';
export const FETCH_EDIT_PROFILE_USER_FAILURE = 'FETCH_EDIT_PROFILE_USER_FAILURE';

export const FETCH_BOOKED_PROPERTY_REQUEST = 'FETCH_BOOKED_PROPERTY_REQUEST';
export const FETCH_BOOKED_PROPERTY_SUCCESS = 'FETCH_BOOKED_PROPERTY_SUCCESS';
export const FETCH_BOOKED_PROPERTY_FAILURE = 'FETCH_BOOKED_PROPERTY_FAILURE';

export const FETCH_BOOKED_ENQUIRY_REQUEST = 'FETCH_BOOKED_ENQUIRY_REQUEST';
export const FETCH_BOOKED_ENQUIRY_SUCCESS = 'FETCH_BOOKED_ENQUIRY_SUCCESS';
export const FETCH_BOOKED_ENQUIRY_FAILURE = 'FETCH_BOOKED_ENQUIRY_FAILURE';

export const FOOTER_PAGE_REQUEST = 'FOOTER_PAGE_REQUEST';
export const FOOTER_PAGE_SUCCESS = 'FOOTER_PAGE_SUCCESS';
export const FOOTER_PAGE_FAILURE = 'FOOTER_PAGE_FAILURE';

export const FETCH_PACKAGE_DATA_SUCCESS = 'FETCH_PACKAGE_DATA_SUCCESS';
export const FETCH_PACKAGE_DATA_REQUEST = 'FETCH_PACKAGE_DATA_REQUEST';
export const FETCH_PACKAGE_DATA_FAILURE = 'FETCH_PACKAGE_DATA_FAILURE';

export const FETCH_PACKAGE_PURCHASE_SUCCESS = 'FETCH_PACKAGE_PURCHASE_SUCCESS';
export const FETCH_PACKAGE_PURCHASE_REQUEST = 'FETCH_PACKAGE_PURCHASE_REQUEST';
export const FETCH_PACKAGE_PURCHASE_FAILURE = 'FETCH_PACKAGE_PURCHASE_FAILURE';
 
export const FAQ_PAGE_REQUEST = 'FAQ_PAGE_REQUEST';
export const FAQ_PAGE_SUCCESS = 'FAQ_PAGE_SUCCESS';
export const FAQ_PAGE_FAILURE = 'FAQ_PAGE_FAILURE';

export const FETCH_CANCEL_BOOK_REQUEST = 'FETCH_CANCEL_BOOK_REQUEST';
export const FETCH_CANCEL_BOOK_SUCCESS = 'FETCH_CANCEL_BOOK_SUCCESS';
export const FETCH_CANCEL_BOOK_FAILURE = 'FETCH_CANCEL_BOOK_FAILURE';

export const FETCH_INQUIRY_REQUEST = 'FETCH_INQUIRY_REQUEST';
export const FETCH_INQUIRY_SUCCESS = 'FETCH_INQUIRY_SUCCESS';
export const FETCH_INQUIRY_FAILURE = 'FETCH_INQUIRY_FAILURE';

export const FETCH_REVIEW_REQUEST = 'FETCH_REVIEW_REQUEST';
export const FETCH_REVIEW_SUCCESS = 'FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_FAILURE = 'FETCH_REVIEW_FAILURE';


