import { initReactI18next } from "react-i18next";
import i18n from 'i18next'

i18n.use(initReactI18next).init({
    lng: "",
    resources: {

        en: {
            translation: {
                "LET US GUIDE YOUR HOME": "LET US GUIDE YOUR HOME",
                "Discover a place you'll love to live": "Discover a place you'll love to live",
                "Featured Properties": "Featured Properties",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "See All Listing",
                "Find Properties in These Cities": "Find Properties in These Cities",
                "Dashboard": "Dashboard",
                "Log in": "Log in",
                "Add Proparty": "Add Proparty",
                "Log Out": "Log Out",
                "GoProperty": "GoProperty",
                "Copyright": "Copyright",
                "Google Play": "Google Play",
                "Get in on": "Get in on",
                "Apple Store": "Apple Store",
                "Download on the": "Download on the",
                "Get the app": "Get the app",
                "rd Floor San Francisco": "rd Floor San Francisco",
                "Fifth Avenue": "Fifth Avenue",
                "Our Address": "Our Address",
                "Contact Us": "Contact Us",
                "Conditions": "Conditions",
                "Terms": "Terms",
                "Privacy Policy": "Privacy Policy",
                "Faq": "Faq",
                "Contact": "Contact",
                "Follow Us": "Follow Us",
                "Subscribe to our newsletter to receive our weekly feed": "Subscribe to our newsletter to receive our weekly feed",
                "AddProparty Navigation": "AddProparty Navigation",
                "View More": "View More",
                "Request": "Request",
                "Your Current Wallet Balance is": "Your Current Wallet Balance is",
                "Total Earning": "Total Earning",
                "No Any Booking Available": "No Any Booking Available",
                "Oops": "Oops",
                "Days": "Days",
                "Completed": "Completed",
                "Current Booking": "Current Booking",
                "Back": "Back",
                "Basic Information": "Basic Information",
                "Add More": "Add More",
                "We are glad to see you again!": "We are glad to see you again!",
                "Hello": "Hello",
                "Save": "Save",
                "Preview": "Preview",
                "Images": "Images",
                "Unpublish": "Unpublish",
                "Publish": "Publish",
                "No": "No",
                "Yes": "Yes",
                "Save": "Save",
                "Proceed": "Proceed",
                "Cancel": "Cancel",
                "Paypal": "Paypal",
                "Bank Transfer": "Bank Transfer",
                "UPI": "UPI",
                "Select Type": "Select Type",
                "Payout Request": "Payout Request",
                "Welcome to Your Real Estate Website": "Welcome to Your Real Estate Website",
                "Submit Review": "Submit Review",
                "Maybe Later": "Maybe Later",
                "Write Your Review": "Write Your Review",
                "How was your experience": "How was your experience",
                "Leave A Review": "Leave A Review",
                "Drop Review": "Drop Review",
                "Confirm": "Confirm",
                "Cancel Booking": "Cancel Booking",
                "Note": "Note",
                "Total": "Total",
                "Wallet Amount": "Wallet Amount",
                "Tax": "Tax",
                "Sub Total": "Sub Total",
                "Property Price": "Property Price",
                "Price Section": "Price Section",
                "Transaction Id": "Transaction Id",
                "Booking Status": "Booking Status",
                "Payment Status": "Payment Status",
                "Payment Title": "Payment Title",
                "Phone Number": "Phone Number",
                "Name": "Name",
                "Booking Details": "Booking Details",
                "CheckOut": "CheckOut",
                "CheckIn": "CheckIn",
                "Date&Time": "Date&Time",
                "Log in": "Log in",
                "Have an account": "Have an account",
                "Register": "Register",
                "I agree with terms & conditions": "I agree with terms & conditions",
                "Create an account": "Create an account",
                "Register here": "Register here",
                "Not a member": "Not a member",
                "Login": "Login",
                "Lost your password": "Lost your password",
                "Remember me": "Remember me",
                "Sign into your account": "Sign into your account",
                "Reset": "Reset",
                "Verify OTP": "Verify OTP",
                "Check Number": "Check Number",
                "Reset Password Now": "Reset Password Now",
                "Resend New Code": "Resend New Code",
                "Didn't receive code": "Didn't receive code",
                "Enter Your Otp Value": "Enter Your Otp Value",
                "Reset Your Password": "Reset Your Password",
                "Welcome to Your Real Estate Website": "Welcome to Your Real Estate Website",
                "Select Payment Method": "Select Payment Method",
                "Continue": "Continue",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Choose the right pricing plan for you",
                "Cancel": "Cancel",
                "Confirm": "Confirm",
                "Cancel Booking Request": "Cancel Booking Request",
                "Others": "Others",
                "Personal Reasons": "Personal Reasons",
                "Competing offer": "Competing offer",
                "Seller changes their mind": "Seller changes their mind",
                "Title issues": "Title issues",
                "Change in financial situation": "Change in financial situation",
                "Inspection issues": "Inspection issues",
                "Financing fell through": "Financing fell through",
                "Save Profile": "Save Profile",
                "Number": "Number",
                "Email": "Email",
                "Old Password": "Old Password",
                "Username": "Username",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png",
                "Profile Information": "Profile Information",
                "Select Payment Method": "Select Payment Method",
                "Add Amount": "Add Amount",
                "My Payout": "My Payout",
                "No favorites found": "No favorites found",
                "Action": "Action",
                "Sqrft": "Sqrft",
                "Rate": "Rate",
                "Listing Title": "Listing Title",
                "we glade to see you again!": "we glade to see you again!",
                "Message": "Message",
                "Days": "Days",
                "Amount": "Amount",
                "Package": "Package",
                "Order ID": "Order ID",
                "Your Current Wallet Balance is": "Your Current Wallet Balance is",
                "Notification": "Notification",
                "on your wallet ": "on your wallet ",
                "You get": "You get",
                "You Got": "You Got",
                "complate transaction": "complate transaction",
                "on their  first": "on their  first",
                "Friends get": "Friends get",
                "Friend's Referral Bonus": "Friend's Referral Bonus",
                "with your friends": "with your friends",
                "Share the referral link": "Share the referral link",
                "Share referral link": "Share referral link",
                "Each Friend you refer": "Each Friend you refer",
                "Cancel": "Cancel",
                "Receipt": "Receipt",
                "Total Days": "Total Days",
                "Status": "Status",
                "P_Status": "P_Status",
                "Earn ": "Earn",
                "Available coupons": "Available coupons",
                "Proceed to Checkout": "Proceed to Checkout",
                "Total Payble Amount": "Total Payble Amount",
                "Tax": "Tax",
                "Amount": "Amount",
                "Cart Totals": "Cart Totals",
                "Number of Guest": "Number of Guest",
                "Booking Date": "Booking Date",
                "Booking Details": "Booking Details",
                "Available for Payment": "Available for Payment",
                "Your Balance": "Your Balance",
                "Apply Coupon": "Apply Coupon",
                "Subtotal": "Subtotal",
                "Booking Days": "Booking Days",
                "Price": "Price",
                "FOR": "FOR",
                "Product": "Product",
                "Submit a Tour Request": "Submit a Tour Request",
                "optional": "optional",
                "Note to Owner": "Note to Owner",
                "Phone": "Phone",
                "FeMale": "FeMale",
                "Male": "Male",
                "Please Select Gender": "Please Select Gender",
                "Last Name": "Last Name",
                "First Name": "First Name",
                "Book For Other": "Book For Other",
                "In Person": "In Person",
                "Guest": "Guest",
                "Allowed Max ": "Allowed Max ",
                "Number Of Guest": "Number Of Guest",
                "Schedule a tour": "Schedule a tour",
                "Select a Date": "Select a Date",
                "City": "City",
                "Details": "Details",
                "Property Size": "Property Size",
                "Property Type": "Property Type",
                "Property Status": "Property Status",
                "Facility Details": "Facility Details",
                "Contact Information": "Contact Information",
                "Call To Agent": "Call To Agent",
                "Reviews": "Reviews",
                "Quick Links": "Quick Links",
                "Bathrooms": "Bathrooms",
                "Map": "Map",
                "Bedrooms": "Bedrooms",
                "Address": "Address",
                "Contacted": "Contacted",
                "Photos": "Photos",
                "Favorite": "Favorite",
                "Description": "Description",
                "Check Out": "Check Out",
                "Check In": "Check In",
                "Review Summary": "Review Summary",
                "Recent Transactions": "Recent Transactions",
                "Payment Mode": "Payment Mode",
                "Active": "Active",
                "Book Id": "Book Id",
                "Booking Schedule": "Booking Schedule",
                "Payment Method": "Payment Method",
                "Contact a Property Owner": "Contact a Property Owner",
            }
        },
        ar: {
            translation: {
                "LET US GUIDE YOUR HOME": "دعنا نرشدك إلى منزلك",
                "Discover a place you'll love to live": "اكتشف مكانًا ستحب العيش فيه",
                "Featured Properties": "العقارات المميزة",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "مشاهدة جميع القوائم",
                "Find Properties in These Cities": "البحث عن العقارات في هذه المدن",
                "Dashboard": "لوحة التحكم",
                "Log in": "تسجيل الدخول",
                "Add Proparty": "إضافة Proparty",
                "Log Out": "تسجيل الخروج",
                "GoProperty": "GoProperty",
                "Copyright": "حقوق الطبع والنشر",
                "Google Play": "Google Play",
                "Get in on": "الدخول على",
                "Apple Store": "Apple Store",
                "Download on the": "التنزيل على",
                "Get the app": "الحصول على التطبيق",
                "rd Floor San Francisco": "الطابق الثالث سان فرانسيسكو",
                "Fifth Avenue": "الشارع الخامس",
                "Our Address": "عنواننا",
                "Contact Us": "اتصل بنا",
                "Conditions": "الشروط",
                "Terms": "الشروط",
                "Privacy Policy": "سياسة الخصوصية",
                "Faq": "الأسئلة الشائعة",
                "Contact": "اتصل بنا",
                "Follow Us": "تابعنا",
                "Subscribe to our newsletter to receive our weekly feed": "اشترك في النشرة الإخبارية الخاصة بنا لتلقي موجزنا الأسبوعي",
                "AddProparty Navigation": "إضافة Proparty Navigation",
                "View More": "عرض المزيد",
                "Request": "طلب",
                "Your Current Wallet Balance is": "رصيد محفظتك الحالي هو",
                "Total Earning": "إجمالي الأرباح",
                "No Any Booking Available": "لا يوجد أي حجز متاح",
                "Oops": "عفواً",
                "Days": "أيام",
                "Completed": "مكتمل",
                "Current Booking": "الحجز الحالي",
                "Back": "الرجوع",
                "Basic Information": "المعلومات الأساسية",
                "Add More": "إضافة المزيد",
                "We are glad to see you again!": "يسعدنا رؤيتك مرة أخرى!",
                "Hello": "مرحبًا",
                "Save": "حفظ",
                "Preview": "معاينة",
                "Images": "الصور",
                "Unpublish": "إلغاء النشر",
                "Publish": "نشر",
                "No": "لا",
                "Yes": "نعم",
                "Save": "حفظ",
                "Proceed": "متابعة",
                "Cancel": "إلغاء",
                "Paypal": "Paypal",
                "Bank Transfer": "تحويل بنكي",
                "UPI": "UPI",
                "Select Type": "تحديد النوع",
                "Payout Request": "طلب الدفع",
                "Welcome to Your Real Estate Website": "مرحبًا بك في موقع الويب العقاري الخاص بك",
                "Submit Review": "إرسال المراجعة",
                "Maybe Later": "ربما لاحقًا",
                "Write Your Review": "اكتب مراجعتك",
                "How was your experience": "كيف كانت تجربتك",
                "Leave A Review": "اترك مراجعة",
                "Drop Review": "إسقاط المراجعة",
                "Confirm": "تأكيد",
                "Cancel Booking": "إلغاء الحجز",
                "Note": "ملاحظة",
                "Total": "الإجمالي",
                "Wallet Amount": "مبلغ المحفظة",
                "Tax": "الضريبة",
                "Sub Total": "الإجمالي الفرعي",
                "Property Price": "سعر العقار",
                "Price Section": "قسم السعر",
                "Transaction Id": "معرف المعاملة",
                "Booking Status": "حالة الحجز",
                "Payment Status": "حالة الدفع",
                "Payment Title": "عنوان الدفع",
                "Phone Number": "رقم الهاتف",
                "Name": "الاسم",
                "Booking Details": "تفاصيل الحجز",
                "CheckOut": "الخروج",
                "CheckIn": "الوصول",
                "Date&Time": "التاريخ والوقت",
                "Log in": "تسجيل الدخول",
                "Have an account": "لديك حساب",
                "Register": "التسجيل",
                "I agree with terms & conditions": "أوافق على الشروط والأحكام",
                "Create an account": "إنشاء حساب",
                "Register here": "التسجيل هنا",
                "Not a member": "ليس عضوًا",
                "Login": "تسجيل الدخول",
                "Lost your password": "نسيت كلمة مرورك",
                "Remember me": "تذكرني",
                "Sign into your account": "تسجيل الدخول إلى حسابك",
                "Reset": "إعادة تعيين",
                "Verify OTP": "التحقق من كلمة المرور لمرة واحدة",
                "Check Number": "التحقق من الرقم",
                "Reset Password Now": "إعادة تعيين كلمة المرور الآن",
                "Resend New Code": "إعادة إرسال رمز جديد",
                "Didn't receive code": "لم يتم استلام الرمز",
                "Enter Your Otp Value": "أدخل قيمة كلمة المرور لمرة واحدة",
                "Reset Your Password": "إعادة تعيين كلمة المرور",
                "Welcome to Your Real Estate Website": "مرحبًا بك في موقع الويب العقاري الخاص بك",
                "Select Payment Method": "اختر طريقة الدفع",
                "Continue": "متابعة",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "اختر خطة التسعير المناسبة لك",
                "Cancel": "إلغاء",
                "Confirm": "تأكيد",
                "Cancel Booking Request": "إلغاء طلب الحجز",
                "Others": "آخرون",
                "Personal Reasons": "أسباب شخصية",
                "Competing offer": "عرض منافس",
                "Seller changes their mind": "يغير البائع رأيه",
                "Title issues": "مشاكل في العنوان",
                "Change in financial situation": "تغيير في الوضع المالي",
                "Inspection issues": "مشاكل في التفتيش",
                "Financing fell through": "فشل التمويل",
                "Save Profile": "حفظ الملف الشخصي",
                "Number": "الرقم",
                "Email": "البريد الإلكتروني",
                "Old Password": "كلمة المرور القديمة",
                "Username": "اسم المستخدم",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "الحد الأقصى لحجم الملف هو 1 ميجا بايت، الحد الأدنى للأبعاد: 330x300، والملفات المناسبة هي .jpg و.png",
                "Profile Information": "معلومات الملف الشخصي",
                "Select Payment Method": "اختر طريقة الدفع",
                "Add Amount": "أضف المبلغ",
                "My Payout": "سداد الدفعة",
                "No favorites found": "لم يتم العثور على مفضلات",
                "Action": "الإجراء",
                "Sqrft": "Sqrft",
                "Rate": "قم بتقييم",
                "Listing Title": "عنوان القائمة",
                "we glade to see you again!": "يسعدنا رؤيتك مرة أخرى!",
                "Message": "الرسالة",
                "Days": "الأيام",
                "Amount": "المبلغ",
                "Package": "الحزمة",
                "Order ID": "معرف الطلب",
                "Your Current Wallet Balance is": "رصيد محفظتك الحالي هو",
                "Notification": "الإشعار",
                "on your wallet ": "على محفظتك",
                "You get": "تحصل على",
                "You Got": "لقد حصلت على",
                "complate transaction": "معاملة مجمعة",
                "on their  first": "في أول",
                "Friends get": "أصدقائك يحصلون على",
                "Friend's Referral Bonus": "مكافأة إحالة صديق",
                "with your friends": "مع أصدقائك",
                "Share the referral link": "شارك رابط الإحالة",
                "Share referral link": "شارك رابط الإحالة",
                "Each Friend you refer": "كل صديق تحيله",
                "Cancel": "إلغاء",
                "Receipt": "الإيصال",
                "Total Days": "إجمالي الأيام",
                "Status": "الحالة",
                "P_Status": "P_Status",
                "Earn": "اكسب",
                "Available coupons": "القسائم المتوفرة",
                "Proceed to Checkout": "الانتقال إلى الدفع",
                "Total Payble Amount": "إجمالي المبلغ المستحق الدفع",
                "Tax": "الضريبة",
                "Amount": "المبلغ",
                "Cart Totals": "إجمالي سلة التسوق",
                "Number of Guest": "عدد الضيوف",
                "Booking Date": "تاريخ الحجز",
                "Booking Details": "تفاصيل الحجز",
                "Available for Payment": "متاح للدفع",
                "Your Balance": "رصيدك",
                "Apply Coupon": "تطبيق القسيمة",
                "Subtotal": "المجموع الفرعي",
                "Booking Days": "أيام الحجز",
                "Price": "السعر",
                "FOR": "لـ",
                "Productar": "المنتج",
                "Submit a Tour Request": "إرسال طلب جولة",
                "optional": "اختياري",
                "Note to Owner": "ملاحظة للمالك",
                "Phone": "الهاتف",
                "FeMale": "أنثى",
                "Male": "ذكر",
                "Please Select Gender": "يرجى تحديد الجنس",
                "Last Name": "الاسم الأخير",
                "First Name": "الاسم الأول",
                "Book For Other": "الحجز لشخص آخر",
                "In Person": "شخصيًا",
                "Guest": "الضيف",
                "Allowed Max ": "الحد الأقصى المسموح به",
                "Number Of Guest": "عدد الضيوف",
                "Schedule a tour": "جدولة جولة",
                "Select a Date": "تحديد تاريخ",
                "City": "المدينة",
                "Details": "التفاصيل",
                "Property Size": "حجم العقار",
                "Property Type": "نوع العقار",
                "Property Status": "حالة العقار",
                "Facility Details": "تفاصيل المنشأة",
                "Contact Information": "معلومات الاتصال",
                "Call To Agent": "الاتصال بالوكيل",
                "Reviews": "التعليقات",
                "Quick Links": "روابط سريعة",
                "Bathrooms": "الحمامات",
                "Map": "الخريطة",
                "Bedrooms": "غرف النوم",
                "Address": "العنوان",
                "Contacted": "التواصل",
                "Photos": "الصور",
                "Favorite": "المفضلة",
                "Description": "الوصف",
                "Check Out": "الخروج",
                "Check In": "الوصول",
                "Review Summary": "ملخص المراجعة",
                "Recent Transactions": "المعاملات الأخيرة",
                "Payment Mode": "طريقة الدفع",
                "Active": "نشط",
                "Book Id": "معرف الحجز",
                "Booking Schedule": "جدول الحجز",
                "Payment Method": "طريقة الدفع",
                "Contact a Property Owner": "الاتصال بمالك العقار",


            }
        },
        hi: {
            translation: {
                "LET US GUIDE YOUR HOME": "हमें आपके घर का मार्गदर्शन करने दें",
                "Discover a place you'll love to live": "ऐसी जगह खोजें जहाँ आप रहना पसंद करेंगे",
                "Featured Properties": "फीचर्ड प्रॉपर्टीज़",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "लोरेम इप्सम डोलर सिट एमेट, कॉन्सेक्टुर एडिपिसिंग एलीट",
                "See All Listing": "सभी लिस्टिंग देखें",
                "Find Properties in These Cities": "इन शहरों में प्रॉपर्टीज़ खोजें",
                "Dashboard": "डैशबोर्ड",
                "Log in": "लॉग इन करें",
                "Add Proparty": "प्रोपार्टी जोड़ें",
                "Log Out": "लॉग आउट करें",
                "GoProperty": "गोप्रॉपर्टी",
                "Copyright": "कॉपीराइट",
                "Google Play": "गूगल प्ले",
                "Get in on": "इसमें शामिल हों",
                "Apple Store": "ऐपल स्टोर",
                "Download on the": "डाउनलोड करें",
                "Get the app": "ऐप प्राप्त करें",
                "rd Floor San Francisco": "तीसरी मंजिल सैन फ्रांसिस्को",
                "Fifth Avenue": "पांचवां एवेन्यू",
                "Our Address": "हमारा पता",
                "Contact Us": "हमसे संपर्क करें",
                "Conditions": "शर्तें",
                "Terms": "नियम",
                "Privacy Policy": "गोपनीयता नीति",
                "Faq": "अक्सर पूछे जाने वाले प्रश्न",
                "Contact": "संपर्क करें",
                "Follow Us": "हमें फ़ॉलो करें",
                "Subscribe to our newsletter to receive our weekly feed": "हमारे साप्ताहिक फ़ीड प्राप्त करने के लिए हमारे न्यूज़लेटर की सदस्यता लें",
                "AddProparty Navigation": "प्रोपार्टी नेविगेशन जोड़ें",
                "View More": "अधिक देखें",
                "Request": "अनुरोध करें",
                "Your Current Wallet Balance is": "आपका वर्तमान वॉलेट बैलेंस है",
                "Total Earning": "कुल कमाई",
                "No Any Booking Available": "कोई भी बुकिंग उपलब्ध नहीं है",
                "Oops": "ओह",
                "Days": "दिन",
                "Completed": "पूरा हो गया",
                "Current Booking": "वर्तमान बुकिंग",
                "Back": "वापस",
                "Basic Information": "बुनियादी जानकारी",
                "Add More": "अधिक जोड़ें",
                "We are glad to see you again!": "हमें आपको फिर से देखकर खुशी हुई!",
                "Hello": "नमस्ते",
                "Save": "सहेजें",
                "Preview": "पूर्वावलोकन",
                "Images": "छवियाँ",
                "Unpublish": "अप्रकाशित करें",
                "Publish": "प्रकाशित करें",
                "No": "नहीं",
                "Yes": "हाँ",
                "Save": "सहेजें",
                "Proceed": "आगे बढ़ें",
                "Cancel": "रद्द करें",
                "Paypal": "पेपैल",
                "Bank Transfer": "बैंक हस्तांतरण",
                "UPI": "UPI",
                "Select Type": "प्रकार चुनें",
                "Payout Request": "भुगतान अनुरोध",
                "Welcome to Your Real Estate Website": "आपकी रियल एस्टेट वेबसाइट पर आपका स्वागत है",
                "Submit Review": "समीक्षा सबमिट करें",
                "Maybe Later": "शायद बाद में",
                "Write Your Review": "अपनी समीक्षा लिखें",
                "How was your experience": "आपका अनुभव कैसा रहा",
                "Leave A Review": "समीक्षा छोड़ें",
                "Drop Review": "समीक्षा छोड़ें",
                "Confirm": "पुष्टि करें",
                "Cancel Booking": "बुकिंग रद्द करें",
                "Note": "नोट",
                "Total": "कुल",
                "Wallet Amount": "वॉलेट राशि",
                "Tax": "कर",
                "Sub Total": "उप योग",
                "Property Price": "संपत्ति मूल्य",
                "Price Section": "मूल्य अनुभाग",
                "Transaction Id": "लेनदेन आईडी",
                "Booking Status": "बुकिंग स्थिति",
                "Payment Status": "भुगतान स्थिति",
                "Payment Title": "भुगतान शीर्षक",
                "Phone Number": "फ़ोन नंबर",
                "Name": "नाम",
                "Booking Details": "बुकिंग विवरण",
                "CheckOut": "चेकआउट",
                "CheckIn": "चेकइन",
                "Date&Time": "दिनांक और समय",
                "Log in": "लॉग इन करें",
                "Have an account": "खाता है",
                "Register": "रजिस्टर करें",
                "I agree with terms & conditions": "मैं नियम और शर्तों से सहमत हूँ",
                "Create an account": "खाता बनाएँ",
                "Register here": "यहाँ रजिस्टर करें",
                "Not a member": "सदस्य नहीं हैं",
                "Login": "लॉग इन करें",
                "Lost your password": "अपना पासवर्ड खो दिया",
                "Remember me": "मुझे याद रखें",
                "Sign into your account": "अपने खाते में साइन इन करें",
                "Reset": "रीसेट करें",
                "Verify OTP": "OTP सत्यापित करें",
                "Check Number": "नंबर जांचें",
                "Reset Password Now": "पासवर्ड अभी रीसेट करें",
                "Resend New Code": "नया कोड फिर से भेजें",
                "Didn't receive code": "कोड प्राप्त नहीं हुआ",
                "Enter Your Otp Value": "अपना OTP मान दर्ज करें",
                "Reset Your Password": "अपना पासवर्ड रीसेट करें",
                "Welcome to Your Real Estate Website": "आपकी रियल एस्टेट वेबसाइट में आपका स्वागत है",
                "Select Payment Method": "भुगतान विधि चुनें",
                "Continue": "जारी रखें",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "अपने लिए सही मूल्य निर्धारण योजना चुनें",
                "Cancel": "रद्द करें",
                "Confirm": "पुष्टि करें",
                "Cancel Booking Request": "बुकिंग अनुरोध रद्द करें",
                "Others": "अन्य",
                "Personal Reasons": "व्यक्तिगत कारण",
                "Competing offer": "प्रतिस्पर्धी प्रस्ताव",
                "Seller changes their mind": "विक्रेता अपना विचार बदलता है",
                "Title issues": "शीर्षक मुद्दे",
                "Change in financial situation": "वित्तीय स्थिति में परिवर्तन",
                "Inspection issues": "निरीक्षण मुद्दे",
                "Financing fell through": "वित्तपोषण विफल हो गया",
                "Save Profile": "प्रोफ़ाइल सहेजें",
                "Number": "नंबर",
                "Email": "ईमेल",
                "Old Password": "पुराना पासवर्ड",
                "Username": "उपयोगकर्ता नाम",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "अधिकतम फ़ाइल आकार 1MB है, न्यूनतम आयाम: 330x300, और उपयुक्त फ़ाइलें .jpg और .png हैं",
                "Profile Information": "प्रोफ़ाइल जानकारी",
                "Select Payment Method": "भुगतान विधि चुनें",
                "Add Amount": "राशि जोड़ें",
                "My Payout": "मेरा भुगतान",
                "No favorites found": "कोई पसंदीदा नहीं मिला",
                "Action": "कार्रवाई",
                "Sqrft": "Sqrft",
                "Rate": "दर",
                "Listing Title": "सूची शीर्षक",
                "we glade to see you again!": "हमें आपको फिर से देखकर खुशी हुई!",
                "Message": "संदेश",
                "Days": "दिन",
                "Amount": "राशि",
                "Package": "पैकेज",
                "Order ID": "ऑर्डर आईडी",
                "Your Current Wallet Balance is": "आपका वर्तमान वॉलेट बैलेंस",
                "Notification": "नोटिफिकेशन",
                "on your wallet ": "आपके वॉलेट पर",
                "You get": "आपको मिलता है",
                "You Got": "आपको मिला",
                "complate transaction": "पूरा लेनदेन",
                "on their  first": "उनके पहले",
                "Friends get": "दोस्तों को",
                "Friend's Referral Bonus": "दोस्तों का रेफरल बोनस",
                "with your friends": "मिलता है",
                "Share the referral link": "अपने दोस्तों के साथ",
                "Share referral link": "रेफरल लिंक शेयर करें",
                "Each Friend you refer": "आपके द्वारा रेफर किया गया प्रत्येक दोस्त",
                "Cancel": "रद्द करें",
                "Receipt": "रसीद",
                "Total Days": "कुल दिन",
                "Status": "स्थिति",
                "P_Status": "P_स्थिति",
                "Earn ": "कमाएँ",
                "Available coupons": "उपलब्ध कूपन",
                "Proceed to Checkout": "चेकआउट के लिए आगे बढ़ें",
                "Total Payble Amount": "कुल देय राशि",
                "Tax": "कर",
                "Amount": "राशि",
                "Cart Totals": "कार्ट का योग",
                "Number of Guest": "अतिथियों की संख्या",
                "Booking Date": "बुकिंग तिथि",
                "Booking Details": "बुकिंग विवरण",
                "Available for Payment": "भुगतान के लिए उपलब्ध",
                "Your Balance": "आपका बैलेंस",
                "Apply Coupon": "कूपन लागू करें",
                "Subtotal": "उप-योग",
                "Booking Days": "बुकिंग दिन",
                "Price": "कीमत",
                "FOR": "के लिए",
                "Product": "उत्पाद",
                "Submit a Tour Request": "टूर अनुरोध सबमिट करें",
                "optional": "वैकल्पिक",
                "Note to Owner": "मालिक को नोट करें",
                "Phone": "फ़ोन",
                "FeMale": "महिला",
                "Male": "पुरुष",
                "Please Select Gender": "कृपया लिंग चुनें",
                "Last Name": "अंतिम नाम",
                "First Name": "पहला नाम",
                "Book For Other": "अन्य के लिए बुक करें",
                "In Person": "व्यक्तिगत रूप से",
                "Guest": "अतिथि",
                "Allowed Max ": "अनुमति दी गई अधिकतम",
                "Number Of Guest": "अतिथियों की संख्या",
                "Schedule a tour": "शेड्यूल करें दौरा",
                "Select a Date": "तारीख चुनें",
                "City": "शहर",
                "Details": "विवरण",
                "Property Size": "संपत्ति का आकार",
                "Property Type": "संपत्ति का प्रकार",
                "Property Status": "संपत्ति की स्थिति",
                "Facility Details": "सुविधा का विवरण",
                "Contact Information": "संपर्क जानकारी",
                "Call To Agent": "एजेंट को कॉल करें",
                "Reviews": "समीक्षाएँ",
                "Quick Links": "त्वरित लिंक",
                "Bathrooms": "बाथरूम",
                "Map": "मानचित्र",
                "Bedrooms": "बेडरूम",
                "Address": "पता",
                "Contacted": "संपर्क",
                "Photos": "फ़ोटो",
                "Favorite": "पसंदीदा",
                "Description": "विवरण",
                "Check Out": "चेक आउट",
                "Check In": "चेक इन",
                "Review Summary": "समीक्षा सारांश",
                "Recent Transactions": "हाल के लेन-देन",
                "Payment Mode": "भुगतान मोड",
                "Active": "सक्रिय",
                "Book Id": "बुक आईडी",
                "Booking Schedule": "बुकिंग शेड्यूल",
                "Payment Method": "भुगतान विधि",
                "Contact a Property Owner": "संपत्ति स्वामी से संपर्क करें",
            }
        },
        sp: {
            translation: {
                "LET US GUIDE YOUR HOME": "DEJA QUE NOSOTROS GUIEMOS TU HOGAR",
                "Discover a place you'll love to live": "Descubre un lugar en el que te encantará vivir",
                "Featured Properties": "Propiedades Destacadas",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "Ver todos los listados",
                "Find Properties in These Cities": "Buscar propiedades en estas ciudades",
                "Dashboard": "Panel de control",
                "Log in": "Iniciar sesión",
                "Add Proparty": "Agregar Proparty",
                "Log Out": "Cerrar sesión",
                "GoProperty": "GoProperty",
                "Copyright": "Copyright",
                "Google Play": "Google Play",
                "Get in on": "Entrar en",
                "Apple Store": "Apple Store",
                "Download on the": "Descargar en",
                "Get the app": "Obtener la aplicación",
                "rd Floor San Francisco": "3er piso San Francisco",
                "Fifth Avenue": "Quinta Avenida",
                "Our Address": "Nuestra dirección",
                "Contact Us": "Contáctanos",
                "Conditions": "Condiciones",
                "Terms": "Términos",
                "Privacy Policy": "Política de privacidad",
                "Faq": "Preguntas frecuentes",
                "Contact": "Contáctanos",
                "Follow Us": "Síguenos",
                "Subscribe to our newsletter to receive our weekly feed": "Suscríbete a nuestro boletín para recibir nuestro feed semanal",
                "AddProparty Navigation": "Navegación de AddProparty",
                "View More": "Ver más",
                "Request": "Solicitar",
                "Your Current Wallet Balance is": "El saldo actual de tu billetera es",
                "Total Earning": "Ganancias totales",
                "No Any Booking Available": "No hay ninguna reserva disponible",
                "Oops": "Oops",
                "Days": "Días",
                "Completed": "Completado",
                "Current Booking": "Reserva actual",
                "Back": "Volver",
                "Basic Information": "Información básica",
                "Add More": "Agregar más",
                "We are glad to see you again!": "¡Nos alegra volver a verte!",
                "Hello": "Hola",
                "Save": "Guardar",
                "Preview": "Vista previa",
                "Images": "Imágenes",
                "Unpublish": "Cancelar publicación",
                "Publish": "Publicar",
                "No": "No",
                "Yes": "Sí",
                "Save": "Guardar",
                "Proceed": "Continuar",
                "Cancel": "Cancelar",
                "Paypal": "Paypal",
                "Bank Transfer": "Transferencia bancaria",
                "UPI": "UPI",
                "Select Type": "Seleccionar tipo",
                "Payout Request": "Solicitud de pago",
                "Welcome to Your Real Estate Website": "Bienvenido a su sitio web de bienes raíces",
                "Submit Review": "Enviar opinión",
                "Maybe Later": "Quizás más tarde",
                "Write Your Review": "Escriba su opinión",
                "How was your experience": "¿Cómo fue su experiencia?",
                "Leave A Review": "Dejar una opinión",
                "Drop Review": "Dejar opinión",
                "Confirm": "Confirmar",
                "Cancel Booking": "Cancelar reserva",
                "Note": "Nota",
                "Total": "Total",
                "Wallet Amount": "Monto de la billetera",
                "Tax": "Impuestos",
                "Sub Total": "Subtotal",
                "Property Price": "Precio de la propiedad",
                "Price Section": "Sección de precios",
                "Transaction Id": "ID de transacción",
                "Booking Status": "Estado de la reserva",
                "Payment Status": "Estado del pago",
                "Payment Title": "Título del pago",
                "Phone Number": "Número de teléfono",
                "Name": "Nombre",
                "Booking Details": "Detalles de la reserva",
                "CheckOut": "CheckOut",
                "CheckIn": "CheckIn",
                "Date&Time": "Fecha y hora",
                "Log in": "Iniciar sesión",
                "Have an account": "Tiene una cuenta",
                "Register": "Registrarse",
                "I agree with terms & conditions": "Estoy de acuerdo con los términos y condiciones",
                "Create an account": "Crear una cuenta",
                "Register here": "Registrarse aquí",
                "Not a member": "No es miembro",
                "Login": "Iniciar sesión",
                "Lost your password": "Olvidó su contraseña",
                "Remember me": "Recuérdeme",
                "Sign into your account": "Inicie sesión en su cuenta",
                "Reset": "Restablecer",
                "Verify OTP": "Verificar OTP",
                "Check Number": "Verificar número",
                "Reset Password Now": "Restablecer contraseña ahora",
                "Resend New Code": "Reenviar nuevo código",
                "Didn't receive code": "No recibió el código",
                "Enter Your Otp Value": "Ingrese su valor de OTP",
                "Reset Your Password": "Restablezca su contraseña",
                "Welcome to Your Real Estate Website": "Bienvenido a su sitio web de bienes raíces",
                "Select Payment Method": "Seleccione el método de pago",
                "Continue": "Continuar",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Elija el plan de precios adecuado para usted",
                "Cancel": "Cancelar",
                "Confirm": "Confirmar",
                "Cancel Booking Request": "Cancelar solicitud de reserva",
                "Others": "Otros",
                "Personal Reasons": "Razones personales",
                "Competing offer": "Oferta de la competencia",
                "Seller changes their mind": "El vendedor cambia de opinión",
                "Title issues": "Problemas con el título",
                "Change in financial situation": "Cambio en la situación financiera",
                "Inspection issues": "Problemas de inspección",
                "Financing fell through": "Fracasó la financiación",
                "Save Profile": "Guardar perfil",
                "Number": "Número",
                "Email": "Correo electrónico",
                "Old Password": "Contraseña anterior",
                "Username": "Nombre de usuario",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "El tamaño máximo de archivo es 1 MB, la dimensión mínima: 330x300 y los archivos adecuados son .jpg & .png",
                "Profile Information": "Información del perfil",
                "Select Payment Method": "Seleccionar método de pago",
                "Add Amount": "Agregar monto",
                "My Payout": "Mi pago",
                "No favorites found": "No se encontraron favoritos",
                "Action": "Acción",
                "Sqrft": "Metros cuadrados",
                "Rate": "Tarifa",
                "Listing Title": "Título del anuncio",
                "we glade to see you again!": "¡Nos alegra volver a verte!",
                "Message": "Mensaje",
                "Days": "Días",
                "Amount": "Monto",
                "Package": "Paquete",
                "Order ID": "ID de pedido",
                "Your Current Wallet Balance is": "Su saldo actual en la billetera es",
                "Notification": "Notificación",
                "on your wallet ": "en su billetera",
                "You get": "Usted recibe",
                "You Got": "Usted recibió",
                "complate transaction": "transacción completa",
                "on their  first": "en su primera",
                "Friends get": "Amigos reciben",
                "Friend's Referral Bonus": "Bono por recomendación de amigos",
                "with your friends": "con sus amigos",
                "Share the referral link": "Compartir el enlace de recomendación",
                "Share referral link": "Compartir el enlace de recomendación",
                "Each Friend you refer": "Cada amigo que recomiende",
                "Cancel": "Cancelar",
                "Receipt": "Recibo",
                "Total Days": "Días totales",
                "Status": "Estado",
                "P_Status": "P_Status",
                "Earn ": "Gana",
                "Available coupons": "Cupones disponibles",
                "Proceed to Checkout": "Proceder al pago",
                "Total Payble Amount": "Monto total a pagar",
                "Tax": "Impuestos",
                "Amount": "Monto",
                "Cart Totals": "Totales del carrito",
                "Number of Guest": "Número de invitados",
                "Booking Date": "Fecha de reserva",
                "Booking Details": "Detalles de la reserva",
                "Available for Payment": "Disponible para pago",
                "Your Balance": "Su saldo",
                "Apply Coupon": "Aplicar cupón",
                "Subtotal": "Subtotal",
                "Booking Days": "Días de reserva",
                "Price": "Precio",
                "FOR": "PARA",
                "Product": "Producto",
                "Submit a Tour Request": "Enviar una solicitud de recorrido",
                "optional": "opcional",
                "Note to Owner": "Nota para el propietario",
                "Phone": "Teléfono",
                "FeMale": "Mujer",
                "Male": "Hombre",
                "Please Select Gender": "Seleccione género",
                "Last Name": "Apellido",
                "First Name": "Nombre",
                "Book For Other": "Reservar para otro",
                "In Person": "En persona",
                "Guest": "Invitado",
                "Allowed Max ": "Máximo permitido",
                "Number Of Guest": "Número de invitados",
                "Schedule a tour": "Programar una Tour",
                "Select a Date": "Seleccione una fecha",
                "City": "Ciudad",
                "Details": "Detalles",
                "Property Size": "Tamaño de la propiedad",
                "Property Type": "Tipo de propiedad",
                "Property Status": "Estado de la propiedad",
                "Facility Details": "Detalles de las instalaciones",
                "Contact Information": "Información de contacto",
                "Call To Agent": "Llamar al agente",
                "Reviews": "Reseñas",
                "Quick Links": "Enlaces rápidos",
                "Bathrooms": "Baños",
                "Map": "Mapa",
                "Bedrooms": "Dormitorios",
                "Address": "Dirección",
                "Contacted": "Contactado",
                "Photos": "Fotos",
                "Favorite": "Favorito",
                "Description": "Descripción",
                "Check Out": "Check Out",
                "Check In": "Check In",
                "Review Summary": "Resumen de reseñas",
                "Recent Transactions": "Transacciones recientes",
                "Payment Mode": "Modo de pago",
                "Active": "Activo",
                "Book Id": "ID de reserva",
                "Booking Schedule": "Calendario de reservas",
                "Payment Method": "Método de pago",
                "Contact a Property Owner": "Contacta a un propietario",

            }
        },
        fr: {
            translation: {
                "LET US GUIDE YOUR HOME": "LAISSEZ-NOUS GUIDER VOTRE MAISON",
                "Discover a place you'll love to live": "Découvrez un endroit où vous aimerez vivre",
                "Featured Properties": "Propriétés en vedette",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "Voir toutes les annonces",
                "Find Properties in These Cities": "Trouver des propriétés dans ces villes",
                "Dashboard": "Tableau de bord",
                "Log in": "Connexion",
                "Add Proparty": "Ajouter Proparty",
                "Log Out": "Déconnexion",
                "GoProperty": "GoProperty",
                "Copyright": "Copyright",
                "Google Play": "Google Play",
                "Get in on": "Participez sur",
                "Apple Store": "Apple Store",
                "Download on the": "Télécharger sur le",
                "Get the app": "Obtenir l'application",
                "rd Floor San Francisco": "rd Floor San Francisco",
                "Fifth Avenue": "Fifth Avenue",
                "Our Address": "Notre adresse",
                "Contact Us": "Contactez-nous",
                "Conditions": "Conditions",
                "Terms": "Conditions",
                "Privacy Policy": "Politique de confidentialité",
                "Faq": "Faq",
                "Contact": "Contact",
                "Follow Us": "Suivez-nous",
                "Subscribe to our newsletter to receive our weekly feed": "Abonnez-vous à notre newsletter pour recevoir notre flux hebdomadaire",
                "AddProparty Navigation": "AddProparty Navigation",
                "View More": "Voir plus",
                "Request": "Demande",
                "Your Current Wallet Balance is": "Le solde actuel de votre portefeuille est de",
                "Total Earning": "Gain total",
                "No Any Booking Available": "Aucune réservation disponible",
                "Oops": "Oups",
                "Days": "Jours",
                "Completed": "Terminé",
                "Current Booking": "Réservation en cours",
                "Back": "Retour",
                "Basic Information": "Informations de base",
                "Add More": "Ajouter plus",
                "We are glad to see you again!": "Nous sommes heureux de vous revoir !",
                "Hello": "Bonjour",
                "Save": "Enregistrer",
                "Preview": "Aperçu",
                "Images": "Images",
                "Unpublish": "Annuler la publication",
                "Publish": "Publier",
                "No": "Non",
                "Yes": "Oui",
                "Save": "Enregistrer",
                "Proceed": "Continuer",
                "Cancel": "Annuler",
                "Paypal": "Paypal",
                "Bank Transfer": "Virement bancaire",
                "UPI": "UPI",
                "Select Type": "Sélectionner le type",
                "Payout Request": "Demande de paiement",
                "Welcome to Your Real Estate Website": "Bienvenue sur votre site Web immobilier",
                "Submit Review": "Envoyer un avis",
                "Maybe Later": "Peut-être plus tard",
                "Write Your Review": "Rédigez votre avis",
                "How was your experience": "Comment s'est passée votre expérience",
                "Leave A Review": "Laisser un avis",
                "Drop Review": "Abandonner l'avis",
                "Confirm": "Confirmer",
                "Cancel Booking": "Annuler la réservation",
                "Note": "Remarque",
                "Total": "Total",
                "Wallet Amount": "Montant du portefeuille",
                "Tax": "Taxe",
                "Sub Total": "Sous-total",
                "Property Price": "Prix de la propriété",
                "Price Section": "Section de prix",
                "Transaction Id": "Identifiant de transaction",
                "Booking Status": "Statut de réservation",
                "Payment Status": "Statut de paiement",
                "Payment Title": "Titre du paiement",
                "Phone Number": "Numéro de téléphone",
                "Name": "Nom",
                "Booking Details": "Détails de la réservation",
                "CheckOut": "Départ",
                "CheckIn": "Arrivée",
                "Date&Time": "Date et heure",
                "Log in": "Connectez-vous",
                "Have an account": "Avoir un compte",
                "Register": "S'inscrire",
                "I agree with terms & conditions": "J'accepte les conditions générales",
                "Create an account": "Créer un compte",
                "Register here": "S'inscrire ici",
                "Not a member": "Pas membre",
                "Login": "Connexion",
                "Lost your password": "Mot de passe perdu",
                "Remember me": "Se souvenir de moi",
                "Sign into your account": "Connectez-vous à votre compte",
                "Reset": "Réinitialiser",
                "Verify OTP": "Vérifier l'OTP",
                "Check Number": "Vérifier le numéro",
                "Reset Password Now": "Réinitialiser le mot de passe maintenant",
                "Resend New Code": "Renvoyer un nouveau code",
                "Didn't receive code": "Je n'ai pas reçu de code",
                "Enter Your Otp Value": "Entrez votre valeur OTP",
                "Reset Your Password": "Réinitialiser votre mot de passe",
                "Welcome to Your Real Estate Website": "Bienvenue sur votre site Web immobilier",
                "Select Payment Method": "Sélectionnez le mode de paiement",
                "Continue": "Continuer",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Choisissez le plan tarifaire qui vous convient",
                "Cancel": "Annuler",
                "Confirm": "Confirmer",
                "Cancel Booking Request": "Annuler la demande de réservation",
                "Others": "Autres",
                "Personal Reasons": "Raisons personnelles",
                "Competing offer": "Offre concurrente",
                "Seller changes their mind": "Le vendeur change d'avis",
                "Title issues": "Problèmes de titre",
                "Change in financial situation": "Changement de situation financière",
                "Inspection issues": "Problèmes d'inspection",
                "Financing fell through": "Le financement a échoué",
                "Save Profile": "Enregistrer le profil",
                "Number": "Numéro",
                "Email": "E-mail",
                "Old Password": "Ancien mot de passe",
                "Username": "Nom d'utilisateur",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "La taille maximale du fichier est de 1 Mo, la dimension minimale : 330 x 300 et les fichiers appropriés sont .jpg et .png",
                "Profile Information": "Informations sur le profil",
                "Select Payment Method": "Sélectionnez le mode de paiement",
                "Add Amount": "Ajouter un montant",
                "My Payout": "Mon paiement",
                "No favorites found": "Aucun favori trouvé",
                "Action": "Action",
                "Sqrft": "Sqrft",
                "Rate": "Taux",
                "Listing Title": "Titre de l'annonce",
                "we glade to see you again!": "nous sommes ravis de vous revoir !",
                "Message": "Message",
                "Days": "Jours",
                "Amount": "Montant",
                "Package": "Forfait",
                "Order ID": "Numéro de commande",
                "Your Current Wallet Balance is": "Le solde actuel de votre portefeuille est",
                "Notification": "Notification",
                "on your wallet ": "sur votre portefeuille",
                "You get": "Vous obtenez",
                "You Got": "Vous avez",
                "complate transaction": "terminez la transaction",
                "on their  first": "lors de leur premier",
                "Friends get": "Les amis obtiennent",
                "Friend's Referral Bonus": "Bonus de parrainage d'ami",
                "with your friends": "avec vos amis",
                "Share the referral link": "Partagez le lien de parrainage",
                "Share referral link": "Partager le lien de parrainage",
                "Each Friend you refer": "Chaque ami que vous parrainez",
                "Cancel": "Annuler",
                "Receipt": "Reçu",
                "Total Days": "Nombre total de jours",
                "Status": "Statut",
                "P_Status": "P_Status",
                "Earn ": "Gagnez",
                "Available coupons": "Coupons disponibles",
                "Proceed to Checkout": "Passer à la caisse",
                "Total Payble Amount": "Montant total à payer",
                "Tax": "Taxe",
                "Amount": "Montant",
                "Cart Totals": "Total du panier",
                "Number of Guest": "Nombre d'invités",
                "Booking Date": "Date de réservation",
                "Booking Details": "Détails de la réservation",
                "Available for Payment": "Disponible pour le paiement",
                "Your Balance": "Votre solde",
                "Apply Coupon": "Appliquer le coupon",
                "Subtotal": "Sous-total",
                "Booking Days": "Jours de réservation",
                "Price": "Prix",
                "FOR": "POUR",
                "Product": "Produit",
                "Submit a Tour Request": "Envoyer une demande de visite",
                "optional": "facultatif",
                "Note to Owner": "Remarque au propriétaire",
                "Phone": "Téléphone",
                "FeMale": "Femme",
                "Male": "Homme",
                "Please Select Gender": "Veuillez sélectionner le sexe",
                "Last Name": "Nom de famille",
                "First Name": "Prénom",
                "Book For Other": "Réserver pour un autre",
                "In Person": "En personne",
                "Guest": "Invité",
                "Allowed Max ": "Maximum autorisé",
                "Number Of Guest": "Nombre d'invités",
                "Schedule a tour": "Planifier une visite",
                "Select a Date": "Sélectionnez un Date",
                "City": "Ville",
                "Details": "Détails",
                "Property Size": "Superficie de la propriété",
                "Property Type": "Type de propriété",
                "Property Status": "Statut de la propriété",
                "Facility Details": "Détails de l'installation",
                "Contact Information": "Coordonnées",
                "Call To Agent": "Appeler l'agent",
                "Reviews": "Avis",
                "Quick Links": "Liens rapides",
                "Bathrooms": "Salles de bain",
                "Map": "Carte",
                "Bedrooms": "Chambres",
                "Address": "Adresse",
                "Contacted": "Contacté",
                "Photos": "Photos",
                "Favorite": "Favori",
                "Description": "Description",
                "Check Out": "Départ",
                "Check In": "Arrivée",
                "Review Summary": "Résumé des avis",
                "Recent Transactions": "Transactions récentes",
                "Payment Mode": "Mode de paiement",
                "Active": "Actif",
                "Book Id": "Identifiant de réservation",
                "Booking Schedule": "Calendrier de réservation",
                "Payment Method": "Mode de paiement",
                "Contact a Property Owner": "Contacter un propriétaire",

            }
        },
        gr: {
            translation: {
                "LET US GUIDE YOUR HOME": "LASSEN SIE SICH VON UNS DURCH IHR ZUHAUSE FÜHREN",
                "Discover a place you'll love to live": "Entdecken Sie einen Ort, an dem Sie gerne leben werden",
                "Featured Properties": "Ausgewählte Immobilien",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "Alle Angebote anzeigen",
                "Find Properties in These Cities": "Immobilien in diesen Städten finden",
                "Dashboard": "Dashboard",
                "Log in": "Anmelden",
                "Add Proparty": "Proparty hinzufügen",
                "Log Out": "Abmelden",
                "GoProperty": "GoProperty",
                "Copyright": "Copyright",
                "Google Play": "Google Play",
                "Get in on": "Machen Sie mit",
                "Apple Store": "Apple Store",
                "Download on the": "Download im",
                "Get the app": "Holen Sie sich die App",
                "rd Floor San Francisco": "3. Stock San Francisco",
                "Fifth Avenue": "Fifth Avenue",
                "Our Address": "Unsere Adresse",
                "Contact Us": "Kontaktieren Sie uns",
                "Conditions": "Bedingungen",
                "Terms": "Bedingungen",
                "Privacy Policy": "Datenschutzrichtlinie",
                "Faq": "FAQ",
                "Contact": "Kontakt",
                "Follow Us": "Folgen Sie uns",
                "Subscribe to our newsletter to receive our weekly feed": "Abonnieren Sie unseren Newsletter, um unseren wöchentlichen Feed zu erhalten",
                "AddProparty Navigation": "AddProparty Navigation",
                "View More": "Mehr anzeigen",
                "Request": "Anfrage",
                "Your Current Wallet Balance is": "Ihr aktueller Wallet-Kontostand ist",
                "Total Earning": "Gesamtverdienst",
                "No Any Booking Available": "Keine Buchung verfügbar",
                "Oops": "Ups",
                "Days": "Tage",
                "Completed": "Abgeschlossen",
                "Current Booking": "Aktuelle Buchung",
                "Back": "Zurück",
                "Basic Information": "Grundlegende Informationen",
                "Add More": "Mehr hinzufügen",
                "We are glad to see you again!": "Wir freuen uns, Sie wiederzusehen!",
                "Hello": "Hallo",
                "Save": "Speichern",
                "Preview": "Vorschau",
                "Images": "Bilder",
                "Unpublish": "Veröffentlichung aufheben",
                "Publish": "Veröffentlichen",
                "No": "Nein",
                "Yes": "Ja",
                "Save": "Speichern",
                "Proceed": "Fortfahren",
                "Cancel": "Abbrechen",
                "Paypal": "Paypal",
                "Bank Transfer": "Banküberweisung",
                "UPI": "UPI",
                "Select Type": "Typ auswählen",
                "Payout Request": "Auszahlungsanforderung",
                "Welcome to Your Real Estate Website": "Willkommen auf Ihrer Immobilien-Website",
                "Submit Review": "Bewertung abgeben",
                "Maybe Later": "Vielleicht später",
                "Write Your Review": "Schreiben Sie Ihre Bewertung",
                "How was your experience": "Wie war Ihre Erfahrung",
                "Leave A Review": "Eine Bewertung abgeben",
                "Drop Review": "Bewertung löschen",
                "Confirm": "Bestätigen",
                "Cancel Booking": "Buchung stornieren",
                "Note": "Hinweis",
                "Total": "Gesamt",
                "Wallet Amount": "Wallet-Betrag",
                "Tax": "Steuer",
                "Sub Total": "Zwischensumme",
                "Property Price": "Immobilienpreis",
                "Price Section": "Preisbereich",
                "Transaction Id": "Transaktions-ID",
                "Booking Status": "Buchungsstatus",
                "Payment Status": "Zahlungsstatus",
                "Payment Title": "Zahlungstitel",
                "Phone Number": "Telefonnummer",
                "Name": "Name",
                "Booking Details": "Buchungsdetails",
                "CheckOut": "Checkout",
                "CheckIn": "Checkin",
                "Date&Time": "Datum und Uhrzeit",
                "Log in": "Anmelden",
                "Have an account": "Konto besitzen",
                "Register": "Registrieren",
                "I agree with terms & conditions": "Ich stimme den Geschäftsbedingungen zu",
                "Create an account": "Konto erstellen",
                "Register here": "Hier registrieren",
                "Not a member": "Kein Mitglied",
                "Login": "Anmelden",
                "Lost your password": "Passwort vergessen",
                "Remember me": "Angemeldet bleiben",
                "Sign into your account": "Bei Ihrem Konto anmelden",
                "Reset": "Zurücksetzen",
                "Verify OTP": "OTP bestätigen",
                "Check Number": "Nummer prüfen",
                "Reset Password Now": "Passwort jetzt zurücksetzen",
                "Resend New Code": "Neuen Code erneut senden",
                "Didn't receive code": "Code nicht erhalten",
                "Enter Your Otp Value": "OTP-Wert eingeben",
                "Reset Your Password": "Passwort zurücksetzen",
                "Welcome to Your Real Estate Website": "Willkommen auf Ihrer Immobilien-Website",
                "Select Payment Method": "Zahlungsmethode auswählen",
                "Continue": "Weiter",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Wählen Sie den richtigen Preisplan für sich",
                "Cancel": "Abbrechen",
                "Confirm": "Bestätigen",
                "Cancel Booking Request": "Buchungsanfrage stornieren",
                "Others": "Sonstiges",
                "Personal Reasons": "Persönliche Gründe",
                "Competing offer": "Konkurrierendes Angebot",
                "Seller changes their mind": "Verkäufer ändert seine Meinung",
                "Title issues": "Titelprobleme",
                "Change in financial situation": "Änderung der finanziellen Situation",
                "Inspection issues": "Inspektionsprobleme",
                "Financing fell through": "Finanzierung geplatzt",
                "Save Profile": "Profil speichern",
                "Number": "Nummer",
                "Email": "E-Mail",
                "Old Password": "Altes Passwort",
                "Username": "Benutzername",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "Maximale Dateigröße ist 1 MB, Mindestgröße: 330 x 300, geeignete Dateien sind .jpg und .png",
                "Profile Information": "Profil Informationen",
                "Select Payment Method": "Zahlungsmethode auswählen",
                "Add Amount": "Betrag hinzufügen",
                "My Payout": "Meine Auszahlung",
                "No favorites found": "Keine Favoriten gefunden",
                "Action": "Aktion",
                "Sqrft": "Sqrft",
                "Rate": "Bewerten",
                "Listing Title": "Angebotstitel",
                "we glade to see you again!": "Wir freuen uns, Sie wiederzusehen!",
                "Message": "Nachricht",
                "Days": "Tage",
                "Amount": "Betrag",
                "Package": "Paket",
                "Order ID": "Bestellnummer",
                "Your Current Wallet Balance is": "Ihr aktueller Wallet-Kontostand ist",
                "Notification": "Benachrichtigung",
                "on your wallet ": "auf Ihrem Wallet",
                "You get": "Sie erhalten",
                "You Got": "Sie haben",
                "complate transaction": "eine vollständige Transaktion",
                "on their  first": "beim ersten",
                "Friends get": "Freunde erhalten",
                "Friend's Referral Bonus": "Freundesempfehlungsbonus",
                "with your friends": "mit Ihren Freunden",
                "Share the referral link": "Teilen Sie den Empfehlungslink",
                "Share referral link": "Teilen Sie den Empfehlungslink",
                "Each Friend you refer": "Jeder Freund, den Sie empfehlen",
                "Cancel": "Stornieren",
                "Receipt": "Quittung",
                "Total Days": "Gesamttage",
                "Status": "Status",
                "P_Status": "P_Status",
                "Earn ": "Verdienen",
                "Available coupons": "Verfügbare Gutscheine",
                "Proceed to Checkout": "Zur Kasse gehen",
                "Total Payble Amount": "Gesamtzahlbarer Betrag",
                "Tax": "Steuer",
                "Amount": "Betrag",
                "Cart Totals": "Warenkorbsummen",
                "Number of Guest": "Anzahl der Gäste",
                "Booking Date": "Buchungsdatum",
                "Booking Details": "Buchungsdetails",
                "Available for Payment": "Zur Zahlung verfügbar",
                "Your Balance": "Ihr Guthaben",
                "Apply Coupon": "Gutschein anwenden",
                "Subtotal": "Zwischensumme",
                "Booking Days": "Buchungstage",
                "Price": "Preis",
                "FOR": "FÜR",
                "Product": "Produkt",
                "Submit a Tour Request": "Touranfrage senden",
                "optional": "optional",
                "Note to Owner": "Hinweis an den Eigentümer",
                "Phone": "Telefon",
                "FeMale": "Weiblich",
                "Male": "Männlich",
                "Please Select Gender": "Bitte wählen Sie das Geschlecht",
                "Last Name": "Nachname",
                "First Name": "Vorname",
                "Book For Other": "Buchen für andere",
                "In Person": "Persönlich",
                "Guest": "Gäste",
                "Allowed Max ": "Maximal zulässig",
                "Number Of Guest": "Anzahl der Gäste",
                "Schedule a tour": "Tour planen",
                "Select a Date": "Wählen Sie eine Datum",
                "City": "Stadt",
                "Details": "Details",
                "Property Size": "Immobiliengröße",
                "Property Type": "Immobilientyp",
                "Property Status": "Immobilienstatus",
                "Facility Details": "Einrichtungsdetails",
                "Contact Information": "Kontaktinformationen",
                "Call To Agent": "Makler anrufen",
                "Reviews": "Bewertungen",
                "Quick Links": "Schnellzugriffe",
                "Bathrooms": "Badezimmer",
                "Map": "Karte",
                "Bedrooms": "Schlafzimmer",
                "Address": "Adresse",
                "Contacted": "Kontaktiert",
                "Photos": "Fotos",
                "Favorite": "Favorit",
                "Description": "Beschreibung",
                "Check Out": "Auschecken",
                "Check In": "Einchecken",
                "Review Summary": "Bewertungszusammenfassung",
                "Recent Transactions": "Letzte Transaktionen",
                "Payment Mode": "Zahlungsmodus",
                "Active": "Aktiv",
                "Book Id": "Buchungs-ID",
                "Booking Schedule": "Buchungsplan",
                "Payment Method": "Zahlungsmethode",
                "Contact a Property Owner": "Kontakt zu einem Immobilieneigentümer aufnehmen",
            }
        },
        in: {
            translation: {
                "LET US GUIDE YOUR HOME": "BIARKAN KAMI MEMANDU RUMAH ANDA",
                "Discover a place you'll love to live": "Temukan tempat yang akan Anda sukai untuk ditinggali",
                "Featured Properties": "Properti Unggulan",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "Lihat Semua Iklan",
                "Find Properties in These Cities": "Temukan Properti di Kota-Kota Ini",
                "Dashboard": "Dasbor",
                "Log in": "Masuk",
                "Add Proparty": "Tambahkan Proparty",
                "Log Out": "Keluar",
                "GoProperty": "GoProperty",
                "Copyright": "Hak Cipta",
                "Google Play": "Google Play",
                "Get in on": "Masuk di",
                "Apple Store": "Apple Store",
                "Download on the": "Unduh di",
                "Get the app": "Dapatkan aplikasinya",
                "rd Floor San Francisco": "Lantai 4 San Francisco",
                "Fifth Avenue": "Fifth Avenue",
                "Our Address": "Alamat Kami",
                "Contact Us": "Hubungi Kami",
                "Conditions": "Ketentuan",
                "Terms": "Persyaratan",
                "Privacy Policy": "Kebijakan Privasi",
                "Faq": "Tanya Jawab",
                "Contact": "Hubungi Kami",
                "Follow Us": "Ikuti Kami",
                "Subscribe to our newsletter to receive our weekly feed": "Berlangganan buletin kami untuk menerima umpan mingguan kami",
                "AddProparty Navigation": "Navigasi AddProparty",
                "View More": "Lihat Lainnya",
                "Request": "Permintaan",
                "Your Current Wallet Balance is": "Saldo Dompet Anda Saat Ini adalah",
                "Total Earning": "Total Penghasilan",
                "No Any Booking Available": "Tidak Ada Pemesanan yang Tersedia",
                "Oops": "Ups",
                "Days": "Hari",
                "Completed": "Selesai",
                "Current Booking": "Pemesanan Saat Ini",
                "Back": "Kembali",
                "Basic Information": "Informasi Dasar",
                "Add More": "Tambah Lainnya",
                "We are glad to see you again!": "Kami senang melihat Anda lagi! ",
                "Hello": "Halo",
                "Save": "Simpan",
                "Preview": "Pratinjau",
                "Images": "Gambar",
                "Unpublish": "Batalkan publikasi",
                "Publish": "Publikasikan",
                "No": "Tidak",
                "Yes": "Ya",
                "Save": "Simpan",
                "Proceed": "Lanjutkan",
                "Cancel": "Batalkan",
                "Paypal": "Paypal",
                "Bank Transfer": "Transfer Bank",
                "UPI": "UPI",
                "Select Type": "Pilih Jenis",
                "Payout Request": "Permintaan Pembayaran",
                "Welcome to Your Real Estate Website": "Selamat Datang di Situs Web Real Estat Anda",
                "Submit Review": "Kirim Ulasan",
                "Maybe Later": "Mungkin Nanti",
                "Write Your Review": "Tulis Ulasan Anda",
                "How was your experience": "Bagaimana pengalaman Anda?",
                "Leave A Review": "Tinggalkan Ulasan",
                "Drop Review": "Kirim Ulasan",
                "Confirm": "Konfirmasi",
                "Cancel Booking": "Batalkan Pemesanan",
                "Note": "Catatan",
                "Total": "Total",
                "Wallet Amount": "Jumlah Dompet",
                "Tax": "Pajak",
                "Sub Total": "Sub Total",
                "Property Price": "Harga Properti",
                "Price Section": "Bagian Harga",
                "Transaction Id": "ID Transaksi",
                "Booking Status": "Status Pemesanan",
                "Payment Status": "Status Pembayaran",
                "Payment Title": "Judul Pembayaran",
                "Phone Number": "Nomor Telepon",
                "Name": "Nama",
                "Booking Details": "Detail Pemesanan",
                "CheckOut": "CheckOut",
                "CheckIn": "CheckIn",
                "Date&Time": "Tanggal&Waktu",
                "Log in": "Masuk",
                "Have an account": "Punya akun",
                "Register": "Daftar",
                "I agree with terms & conditions": "Saya setuju dengan syarat & ketentuan",
                "Create an account": "Buat akun",
                "Register here": "Daftar di sini",
                "Not a member": "Bukan anggota",
                "Login": "Masuk",
                "Lost your password": "Lupa kata sandi Anda",
                "Remember me": "Ingat saya",
                "Sign into your account": "Masuk ke akun Anda",
                "Reset": "Atur ulang",
                "Verify OTP": "Verifikasi OTP",
                "Check Number": "Periksa Nomor",
                "Reset Password Now": "Atur Ulang Kata Sandi Sekarang",
                "Resend New Code": "Kirim Ulang Kode Baru",
                "Didn't receive code": "Tidak menerima kode",
                "Enter Your Otp Value": "Masukkan Nilai OTP Anda",
                "Reset Your Password": "Atur Ulang Kata Sandi Anda",
                "Welcome to Your Real Estate Website": "Selamat datang di Situs Web Real Estat Anda",
                "Select Payment Method": "Pilih Metode Pembayaran",
                "Continue": "Lanjutkan",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Pilih paket harga yang tepat untuk Anda",
                "Cancel": "Batalkan",
                "Confirm": "Konfirmasi",
                "Cancel Booking Request": "Batalkan Permintaan Pemesanan",
                "Others": "Lainnya",
                "Personal Reasons": "Alasan Pribadi",
                "Competing offer": "Penawaran pesaing",
                "Seller changes their mind": "Penjual berubah pikiran",
                "Title issues": "Masalah hak milik",
                "Change in financial situation": "Perubahan situasi keuangan",
                "Inspection issues": "Masalah pemeriksaan",
                "Financing fell through": "Pembiayaan gagal",
                "Save Profile": "Simpan Profil",
                "Number": "Nomor",
                "Email": "Email",
                "Old Password": "Kata Sandi Lama",
                "Username": "Nama pengguna",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "Ukuran file maksimum adalah 1MB, Dimensi minimum: 330x300, dan file yang sesuai adalah .jpg & .png",
                "Profile Information": "Profil Informasi",
                "Select Payment Method": "Pilih Metode Pembayaran",
                "Add Amount": "Tambah Jumlah",
                "My Payout": "Pembayaran Saya",
                "No favorites found": "Tidak ada favorit yang ditemukan",
                "Action": "Tindakan",
                "Sqrft": "Luas",
                "Rate": "Tarif",
                "Listing Title": "Judul Iklan",
                "we glade to see you again!": "Kami senang bertemu Anda lagi! Pesan",
                "Message": "Hari",
                "Days": "Jumlah",
                "Amount": "Paket",
                "Package": "ID Pesanan",
                "Order ID": "Saldo Dompet Anda Saat Ini",
                "Your Current Wallet Balance is": "Pemberitahuan",
                "Notification": "di dompet Anda",
                "on your wallet ": "Anda mendapatkan",
                "You get": "Anda Mendapat",
                "You Got": "transaksi lengkap",
                "complate transaction": "pada transaksi pertama mereka",
                "on their  first": "Teman mendapatkan",
                "Friends get": "Bonus Referensi Teman",
                "Friend's Referral Bonus": "dengan teman Anda",
                "with your friends": "Bagikan tautan referensi",
                "Share the referral link": "Bagikan tautan referensi",
                "Share referral link": "Setiap Teman yang Anda referensikan",
                "Each Friend you refer": "Batalkan",
                "Cancel": "Kwitansi",
                "Receipt": "Total Hari",
                "Total Days": "Status",
                "Status": "P_Status",
                "P_Status": "Dapatkan",
                "Earn ": "Kupon yang tersedia",
                "Available coupons": "Lanjutkan ke Kasir",
                "Proceed to Checkout": "Total Jumlah yang Harus Dibayar",
                "Total Payble Amount": "Pajak",
                "Tax": "Jumlah",
                "Amount": "Total Keranjang",
                "Cart Totals": "Jumlah Tamu",
                "Number of Guest": "Tanggal Pemesanan",
                "Booking Date": "Detail Pemesanan",
                "Booking Details": "Tersedia untuk Pembayaran",
                "Available for Payment": "Saldo Anda",
                "Your Balance": "Terapkan Kupon",
                "Apply Coupon": "Subtotal",
                "Subtotal": "Hari Pemesanan",
                "Booking Days": "Harga",
                "Price": "UNTUK",
                "FOR": "Produk",
                "Product": "Kirim Permintaan Tur",
                "Submit a Tour Request": "opsional",
                "optional": "Catatan untuk Pemilik",
                "Note to Owner": "Telepon",
                "Phone": "Wanita",
                "FeMale": "Pria",
                "Male": "Silakan Pilih Jenis Kelamin",
                "Please Select Gender": "Nama Belakang",
                "Last Name": "Nama Depan",
                "First Name": "Pesan untuk Orang Lain",
                "Book For Other": "Secara Langsung",
                "In Person": "Tamu",
                "Guest": "Jumlah Maksimum ",
                "Allowed Max ": "yang Diizinkan",
                "Number Of Guest": "Jumlah Tamu",
                "Schedule a tour": "Jadwalkan tur",
                "Select a Date": "Pilih Tanggal",
                "City": "Kota",
                "Details": "Detail",
                "Property Size": "Ukuran Properti",
                "Property Type": "Jenis Properti",
                "Property Status": "Status Properti",
                "Facility Details": "Detail Fasilitas",
                "Contact Information": "Informasi Kontak",
                "Call To Agent": "Hubungi Agen",
                "Reviews": "Ulasan",
                "Quick Links": "Tautan Cepat",
                "Bathrooms": "Kamar Mandi",
                "Map": "Peta",
                "Bedrooms": "Kamar Tidur",
                "Address": "Alamat",
                "Contacted": "Dihubungi",
                "Photos": "Foto",
                "Favorite": "Favorit",
                "Description": "Deskripsi",
                "Check Out": "Check Out",
                "Check In": "Check In",
                "Review Summary": "Ringkasan Ulasan",
                "Recent Transactions": "Transaksi Terkini",
                "Payment Mode": "Mode Pembayaran",
                "Active": "Aktif",
                "Book Id": "ID Pemesanan",
                "Booking Schedule": "Jadwal Pemesanan",
                "Payment Method": "Metode Pembayaran",
                "Contact a Property Owner": "Hubungi Pemilik Properti",
            }
        },
        sa: {
            translation: {
                "LET US GUIDE YOUR HOME": "KOM ONS LEI JOU HUIS",
                "Discover a place you'll love to live": "Ontdek 'n plek waar jy graag sal bly",
                "Featured Properties": "Uitstal-eienskappe",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "Sien alle lysinskrywings",
                "Find Properties in These Cities": "Vind eiendomme in hierdie stede",
                "Dashboard": "Dashboard",
                "Log in": "Meld aan",
                "Add Proparty": "Voeg Proparty by",
                "Log Out": "Teken uit",
                "GoProperty": "GoProperty",
                "Copyright": "Kopiereg",
                "Google Play": "Google Play",
                "Get in on": "Tree in",
                "Apple Store": "Apple Winkel",
                "Download on the": "Laai af op die",
                "Get the app": "Kry die toepassing",
                "rd Floor San Francisco": "rd Vloer San Francisco",
                "Fifth Avenue": "Vyfde Laan",
                "Our Address": "Ons adres",
                "Contact Us": "Kontak ons",
                "Conditions": "Voorwaardes",
                "Terms": "Bepalings",
                "Privacy Policy": "Privaatheidsbeleid",
                "Faq": "Gereelde vrae",
                "Contact": "Kontak",
                "Follow Us": "Volg Ons",
                "Subscribe to our newsletter to receive our weekly feed": "Teken in op ons nuusbrief om ons weeklikse voer te ontvang",
                "AddProparty Navigation": "Voeg Proparty-navigasie by",
                "View More": "Bekyk meer",
                "Request": "Versoek",
                "Your Current Wallet Balance is": "Jou huidige beursie-saldo is",
                "Total Earning": "Totale verdienste",
                "No Any Booking Available": "Nee Enige bespreking beskikbaar",
                "Oops": "Oeps",
                "Days": "Dae",
                "Completed": "Voltooi",
                "Current Booking": "Huidige bespreking",
                "Back": "Terug",
                "Basic Information": "Basiese inligting",
                "Add More": "Voeg meer by",
                "We are glad to see you again!": "Ons is bly om jou weer te sien!",
                "Hello": "Hallo",
                "Save": "Stoor",
                "Preview": "Voorskou",
                "Images": "Beelde",
                "Unpublish": "Depubliseer",
                "Publish": "Publiseer",
                "No": "Nee",
                "Yes": "Ja",
                "Save": "Stoor",
                "Proceed": "Gaan voort",
                "Cancel": "Kanselleer",
                "Paypal": "Paypal",
                "Bank Transfer": "Bankoorplasing",
                "UPI": "UPI",
                "Select Type": "Kies Tipe",
                "Payout Request": "Uitbetaling Versoek",
                "Welcome to Your Real Estate Website": "Welkom by u eiendomswebwerf",
                "Submit Review": "Dien resensie in",
                "Maybe Later": "Miskien Later",
                "Write Your Review": "Skryf jou resensie",
                "How was your experience": "Hoe was jou ervaring",
                "Leave A Review": "Laat 'n resensie",
                "Drop Review": "Drop Review",
                "Confirm": "Bevestig",
                "Cancel Booking": "Kanselleer bespreking",
                "Note": "Let wel",
                "Total": "Totaal",
                "Wallet Amount": "Bedrag beursie",
                "Tax": "Belasting",
                "Sub Total": "Subtotaal",
                "Property Price": "Eiendomsprys",
                "Price Section": "Prys Afdeling",
                "Transaction Id": "Transaksie-ID",
                "Booking Status": "Bespreking Status",
                "Payment Status": "Betaling Status",
                "Payment Title": "Betaling Titel",
                "Phone Number": "Telefoonnommer",
                "Name": "Naam",
                "Booking Details": "Besprekingsbesonderhede",
                "CheckOut": "Uitcheck",
                "CheckIn": "Meld aan",
                "Date&Time": "Datum & Tyd",
                "Log in": "Meld aan",
                "Have an account": "Het 'n rekening",
                "Register": "Registreer",
                "I agree with terms & conditions": "Ek stem saam met bepalings en voorwaardes",
                "Create an account": "Skep 'n rekening",
                "Register here": "Registreer hier",
                "Not a member": "Nie 'n lid nie",
                "Login": "Teken in",
                "Lost your password": "Het jou wagwoord verloor",
                "Remember me": "Onthou my",
                "Sign into your account": "Meld aan by jou rekening",
                "Reset": "Stel terug",
                "Verify OTP": "Verifieer OTP",
                "Check Number": "Kontroleer nommer",
                "Reset Password Now": "Stel wagwoord nou terug",
                "Resend New Code": "Stuur nuwe kode weer",
                "Didn't receive code": "Het nie kode ontvang nie",
                "Enter Your Otp Value": "Voer jou OTP-waarde in",
                "Reset Your Password": "Stel jou wagwoord terug",
                "Welcome to Your Real Estate Website": "Welkom by u eiendomswebwerf",
                "Select Payment Method": "Kies Betaalmetode",
                "Continue": "Gaan voort",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Kies die regte prysplan vir jou",
                "Cancel": "Kanselleer",
                "Confirm": "Bevestig",
                "Cancel Booking Request": "Kanselleer besprekingsversoek",
                "Others": "Ander",
                "Personal Reasons": "Persoonlike redes",
                "Competing offer": "Mededingende aanbod",
                "Seller changes their mind": "Verkoper verander van plan",
                "Title issues": "Titelkwessies",
                "Change in financial situation": "Verandering in finansiële situasie",
                "Inspection issues": "Inspeksie kwessies",
                "Financing fell through": "Finansiering het deurgeval",
                "Save Profile": "Stoor profiel",
                "Number": "Nommer",
                "Email": "E-pos",
                "Old Password": "Ou wagwoord",
                "Username": "Gebruikersnaam",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "Maksimum lêergrootte is 1MB, minimum afmeting: 330x300, en geskikte lêers is .jpg en .png",
                "Profile Information": "Profielinligting",
                "Select Payment Method": "Kies Betaalmetode",
                "Add Amount": "Voeg bedrag by",
                "My Payout": "My uitbetaling",
                "No favorites found": "Geen gunstelinge gevind nie",
                "Action": "Aksie",
                "Sqrft": "Sqrft",
                "Rate": "Beoordeel",
                "Listing Title": "Lys titel",
                "we glade to see you again!": "ons is bly om jou weer te sien!",
                "Message": "Boodskap",
                "Days": "Dae",
                "Amount": "Bedrag",
                "Package": "Pakket",
                "Order ID": "Bestel ID",
                "Your Current Wallet Balance is": "Jou huidige beursie-saldo is",
                "Notification": "Kennisgewing",
                "on your wallet ": "op jou beursie",
                "You get": "Jy kry",
                "You Got": "Jy het",
                "complate transaction": "transaksie voltooi",
                "on their  first": "op hul eerste",
                "Friends get": "Vriende kry",
                "Friend's Referral Bonus": "Vriend se verwysingsbonus",
                "with your friends": "saam met jou vriende",
                "Share the referral link": "Deel die verwysingsskakel",
                "Share referral link": "Deel verwysingsskakel",
                "Each Friend you refer": "Elke Vriend wat jy verwys",
                "Cancel": "Kanselleer",
                "Receipt": "Kwitansie",
                "Total Days": "Totale dae",
                "Status": "Status",
                "P_Status": "P_Status",
                "Earn ": "Verdien",
                "Available coupons": "Beskikbare koepons",
                "Proceed to Checkout": "Gaan voort na Checkout",
                "Total Payble Amount": "Totale Betaalbare Bedrag",
                "Tax": "Belasting",
                "Amount": "Bedrag",
                "Cart Totals": "Kartotale",
                "Number of Guest": "Aantal gaste",
                "Booking Date": "Besprekingsdatum",
                "Booking Details": "Besprekingsbesonderhede",
                "Available for Payment": "Beskikbaar vir betaling",
                "Your Balance": "Jou balans",
                "Apply Coupon": "Pas koepon toe",
                "Subtotal": "Subtotaal",
                "Booking Days": "Besprekingsdae",
                "Price": "Prys",
                "FOR": "VIR",
                "Product": "Produk",
                "Submit a Tour Request": "Dien 'n toerversoek in",
                "optional": "opsioneel",
                "Note to Owner": "Nota aan Eienaar",
                "Phone": "Telefoon",
                "FeMale": "Vroulike",
                "Male": "Manlik",
                "Please Select Gender": "Kies asseblief geslag",
                "Last Name": "Van",
                "First Name": "Voornaam",
                "Book For Other": "Boek Vir Ander",
                "In Person": "Persoonlik",
                "Guest": "Gas",
                "Allowed Max ": "Toegelaat Max",
                "Number Of Guest": "Aantal gaste",
                "Schedule a tour": "Beplan 'n toer",
                "Select a Date": "Kies 'n datum",
                "City": "Stad",
                "Details": "Besonderhede",
                "Property Size": "Eiendom Grootte",
                "Property Type": "Eiendom Tipe",
                "Property Status": "Eiendom Status",
                "Facility Details": "Fasiliteit Besonderhede",
                "Contact Information": "Kontakinligting",
                "Call To Agent": "Oproep na Agent",
                "Reviews": "Resensies",
                "Quick Links": "Vinnige skakels",
                "Bathrooms": "Badkamers",
                "Map": "Kaart",
                "Bedrooms": "Slaapkamers",
                "Address": "Adres",
                "Contacted": "Gekontak",
                "Photos": "Foto's",
                "Favorite": "Gunsteling",
                "Description": "Beskrywing",
                "Check Out": "Check uit",
                "Check In": "Meld aan",
                "Review Summary": "Hersien Opsomming",
                "Recent Transactions": "Onlangse transaksies",
                "Payment Mode": "Betaalmodus",
                "Active": "Aktief",
                "Book Id": "Boek ID",
                "Booking Schedule": "Besprekingskedule",
                "Payment Method": "Betaalmetode",
                "Contact a Property Owner": "Kontak 'n eiendomseienaar",

            }
        },
        tu: {
            translation: {
                "LET US GUIDE YOUR HOME": "EVİNİZİ REHBERLİK ETMEMİZE İZİN VERİN",
                "Discover a place you'll love to live": "Yaşamayı seveceğiniz bir yer keşfedin",
                "Featured Properties": "Öne Çıkan Özellikler",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "Tüm Listelemeleri Görün",
                "Find Properties in These Cities": "Bu Şehirlerdeki Özellikleri Bulun",
                "Dashboard": "Pano",
                "Log in": "Giriş Yap",
                "Add Proparty": "Proparty Ekle",
                "Log Out": "Çıkış Yap",
                "GoProperty": "GoProperty",
                "Copyright": "Telif Hakkı",
                "Google Play": "Google Play",
                "Get in on": "Üzerine Katılın",
                "Apple Store": "Apple Store",
                "Download on the": "Üzerinden İndirin",
                "Get the app": "Uygulamayı edinin",
                "rd Floor San Francisco": "rd Floor San Francisco",
                "Fifth Avenue": "Fifth Avenue",
                "Our Address": "Adresiniz",
                "Contact Us": "Bize Ulaşın",
                "Conditions": "Koşullar",
                "Terms": "Şartlar",
                "Privacy Policy": "Gizlilik Politikası",
                "Faq": "SSS",
                "Contact": "İletişim",
                "Follow Us": "Bizi Takip Edin",
                "Subscribe to our newsletter to receive our weekly feed": "Haftalık beslememizi almak için bültenimize abone olun",
                "AddProparty Navigation": "AddProparty Navigasyon",
                "View More": "Daha Fazlasını Gör",
                "Request": "İstek",
                "Your Current Wallet Balance is": "Mevcut Cüzdan Bakiyeniz",
                "Total Earning": "Toplam Kazanç",
                "No Any Booking Available": "Mevcut Rezervasyon Yok",
                "Oops": "Oops",
                "Days": "Günler",
                "Completed": "Tamamlandı",
                "Current Booking": "Mevcut Rezervasyon",
                "Back": "Geri",
                "Basic Information": "Temel Bilgiler",
                "Add More": "Daha Fazlasını Ekle",
                "We are glad to see you again!": "Sizi tekrar gördüğümüze sevindik!",
                "Hello": "Merhaba",
                "Save": "Kaydet",
                "Preview": "Önizleme",
                "Images": "Görüntüler",
                "Unpublish": "Yayınlamayı kaldır",
                "Publish": "Yayınla",
                "No": "Hayır",
                "Yes": "Evet",
                "Save": "Kaydet",
                "Proceed": "Devam et",
                "Cancel": "İptal",
                "Paypal": "Paypal",
                "Bank Transfer": "Banka Havalesi",
                "UPI": "UPI",
                "Select Type": "Tür Seç",
                "Payout Request": "Ödeme Talebi",
                "Welcome to Your Real Estate Website": "Emlak Web Sitenize Hoş Geldiniz",
                "Submit Review": "İnceleme Gönder",
                "Maybe Later": "Belki Daha Sonra",
                "Write Your Review": "İncelemenizi Yazın",
                "How was your experience": "Deneyiminiz nasıldı",
                "Leave A Review": "İnceleme Bırak",
                "Drop Review": "İncelemeyi Bırak",
                "Confirm": "Onayla",
                "Cancel Booking": "Rezervasyonu İptal Et",
                "Note": "Not",
                "Total": "Toplam",
                "Wallet Amount": "Cüzdan Tutarı",
                "Tax": "Vergi",
                "Sub Total": "Ara Toplam",
                "Property Price": "Mülk Fiyatı",
                "Price Section": "Fiyat Bölümü",
                "Transaction Id": "İşlem Kimliği",
                "Booking Status": "Rezervasyon Durumu",
                "Payment Status": "Ödeme Durumu",
                "Payment Title": "Ödeme Başlığı",
                "Phone Number": "Telefon Numarası",
                "Name": "Ad",
                "Booking Details": "Rezervasyon Ayrıntıları",
                "CheckOut": "Çıkış",
                "CheckIn": "Giriş",
                "Date&Time": "Tarih ve Saat",
                "Log in": "Giriş yap",
                "Have an account": "Hesabınız var",
                "Register": "Kayıt ol",
                "I agree with terms & conditions": "Şartlar ve koşulları kabul ediyorum",
                "Create an account": "Hesap oluştur",
                "Register here": "Buraya kaydol",
                "Not a member": "Üye değil",
                "Login": "Giriş yap",
                "Lost your password": "Şifrenizi mi unuttunuz",
                "Remember me": "Beni hatırla",
                "Sign into your account": "Hesabınıza giriş yapın",
                "Reset": "Sıfırla",
                "Verify OTP": "OTP'yi doğrulayın",
                "Check Number": "Numarayı kontrol edin",
                "Reset Password Now": "Şifreyi şimdi sıfırlayın",
                "Resend New Code": "Yeni kodu tekrar gönderin",
                "Didn't receive code": "Kodu almadım",
                "Enter Your Otp Value": "OTP değerinizi girin",
                "Reset Your Password": "Şifrenizi sıfırlayın",
                "Welcome to Your Real Estate Website": "Emlak Web Sitenize Hoş Geldiniz",
                "Select Payment Method": "Ödeme Yöntemini seçin",
                "Continue": "Devam et",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Sizin için doğru fiyatlandırma planını seçin",
                "Cancel": "İptal",
                "Confirm": "Onayla",
                "Cancel Booking Request": "Rezervasyon Talebini iptal et",
                "Others": "Diğerleri",
                "Personal Reasons": "Kişisel Nedenler",
                "Competing offer": "Rakip teklif",
                "Seller changes their mind": "Satıcı fikrini değiştirir",
                "Title issues": "Tapu sorunları",
                "Change in financial situation": "Mali durumda değişiklik",
                "Inspection issues": "Denetim sorunları",
                "Financing fell through": "Finansman başarısız oldu",
                "Save Profile": "Profili kaydet",
                "Number": "Numara",
                "Email": "E-posta",
                "Old Password": "Eski şifre",
                "Username": "Kullanıcı adı",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "Maksimum dosya boyutu 1MB, Minimum boyut: 330x300 ve Uygun dosyalar .jpg ve .png'dir",
                "Profile Information": "Profil Bilgileri",
                "Select Payment Method": "Ödeme Yöntemini Seçin",
                "Add Amount": "Tutar Ekle",
                "My Payout": "Ödemem",
                "No favorites found": "Favori bulunamadı",
                "Action": "Eylem",
                "Sqrft": "Sqrft",
                "Rate": "Puan",
                "Listing Title": "Liste Başlığı",
                "we glade to see you again!": "sizi tekrar gördüğümüze sevindik!",
                "Message": "Mesaj",
                "Days": "Günler",
                "Amount": "Miktar",
                "Package": "Paket",
                "Order ID": "Sipariş Kimliği",
                "Your Current Wallet Balance is": "Mevcut Cüzdan Bakiyeniz",
                "Notification": "Bildirim",
                "on your wallet ": "cüzdanınızda",
                "You get": "Aldığınız",
                "You Got": "Aldığınız",
                "complate transaction": "İşlemi tamamlayın",
                "on their  first": "ilk",
                "Friends get": "Arkadaşlarınız",
                "Friend's Referral Bonus": "Arkadaş Tavsiye Bonusu",
                "with your friends": "alır",
                "Share the referral link": "Tavsiye bağlantısını paylaşın",
                "Share referral link": "Tavsiye bağlantısını paylaşın",
                "Each Friend you refer": "Tavsiye ettiğiniz her Arkadaş",
                "Cancel": "İptal",
                "Receipt": "Makbuz",
                "Total Days": "Toplam Günler",
                "Status": "Durum",
                "P_Status": "P_Durum",
                "Earn ": "Kazan",
                "Available coupons": "Kullanılabilir kuponlar",
                "Proceed to Checkout": "Ödemeye Devam Et",
                "Total Payble Amount": "Toplam Ödenecek Tutar",
                "Tax": "Vergi",
                "Amount": "Tutar",
                "Cart Totals": "Sepet Toplamları",
                "Number of Guest": "Misafir Sayısı",
                "Booking Date": "Rezervasyon Tarihi",
                "Booking Details": "Rezervasyon Ayrıntıları",
                "Available for Payment": "Ödeme İçin Kullanılabilir",
                "Your Balance": "Bakiyeniz",
                "Apply Coupon": "Kuponu Uygula",
                "Subtotal": "Ara Toplam",
                "Booking Days": "Rezervasyon Günleri",
                "Price": "Fiyat",
                "FOR": "İÇİN",
                "Product": "ÜRÜN ",
                "Submit a Tour Request": "Tur Talebi Gönder",
                "optional": "isteğe bağlı",
                "Note to Owner": "Sahibine Not",
                "Phone": "Telefon",
                "FeMale": "Kadın",
                "Male": "Erkek",
                "Please Select Gender": "Lütfen Cinsiyet Seçin",
                "Last Name": "Soyadı",
                "First Name": "Ad",
                "Book For Other": "Başkası İçin Rezervasyon Yap",
                "In Person": "Şahsen",
                "Guest": "Misafir",
                "Allowed Max ": "İzin Verilen Maksimum",
                "Number Of Guest": "Sayı Misafir",
                "Schedule a tour": "Bir tur planlayın",
                "Select a Date": "Bir Tarih seçin",
                "City": "Şehir",
                "Details": "Ayrıntılar",
                "Property Size": "Mülk Boyutu",
                "Property Type": "Mülk Türü",
                "Property Status": "Mülk Durumu",
                "Facility Details": "Tesis Ayrıntıları",
                "Contact Information": "İletişim Bilgileri",
                "Call To Agent": "Temsilciyi Arayın",
                "Reviews": "Yorumlar",
                "Quick Links": "Hızlı Bağlantılar",
                "Bathrooms": "Banyolar",
                "Map": "Harita",
                "Bedrooms": "Yatak Odaları",
                "Address": "Adres",
                "Contacted": "İletişime Geçilen",
                "Photos": "Fotoğraflar",
                "Favorite": "Favori",
                "Description": "Açıklama",
                "Check Out": "Çıkış",
                "Check In": "Giriş",
                "Review Summary": "İnceleme Özeti",
                "Recent Transactions": "Son İşlemler",
                "Payment Mode": "Ödeme Modu",
                "Active": "Aktif",
                "Book Id": "Rezervasyon Kimliği",
                "Booking Schedule": "Rezervasyon Programı",
                "Payment Method": "Ödeme Yöntemi",
                "Contact a Property Owner": "Bir Mülk Sahibiyle İletişime Geçin",

            }
        },
        pu: {
            translation: {
                "LET US GUIDE YOUR HOME": "DEIXE-NOS GUIAR A SUA CASA",
                "Discover a place you'll love to live": "Descubra um lugar que vai adorar viver",
                "Featured Properties": "Propriedades em destaque",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "See All Listing": "Ver todas as listagens",
                "Find Properties in These Cities": "Encontre imóveis nestas cidades",
                "Dashboard": "Painel",
                "Log in": "Conecte-se",
                "Add Proparty": "Adicionar propriedade",
                "Log Out": "Sair",
                "GoProperty": "GoProperty",
                "Copyright": "Direitos autorais",
                "Google Play": "Google Play",
                "Get in on": "Entre",
                "Apple Store": "Loja da Apple",
                "Download on the": "Baixe no",
                "Get the app": "Obtenha a aplicação",
                "rd Floor San Francisco": "3º Andar São Francisco",
                "Fifth Avenue": "Quinta Avenida",
                "Our Address": "O nosso endereço",
                "Contact Us": "Contate-nos",
                "Conditions": "Condições",
                "Terms": "Termos",
                "Privacy Policy": "política de Privacidade",
                "Faq": "Perguntas frequentes",
                "Contact": "Contacto",
                "Follow Us": "Siga-nos",
                "Subscribe to our newsletter to receive our weekly feed": "Subscreva a nossa newsletter para receber o nosso feed semanal",
                "AddProparty Navigation": "Navegação AddProparty",
                "View More": "Ver mais",
                "Request": "Pedido",
                "Your Current Wallet Balance is": "O seu saldo atual da Carteira virtual é",
                "Total Earning": "Ganhos totais",
                "No Any Booking Available": "Nenhuma reserva disponível",
                "Oops": "Ops",
                "Days": "Dias",
                "Completed": "Concluído",
                "Current Booking": "Reserva atual",
                "Back": "Costas",
                "Basic Information": "Informações Básicas",
                "Add More": "Adicionar mais",
                "We are glad to see you again!": "Estamos felizes por vê-lo novamente!",
                "Hello": "Olá",
                "Save": "Guardar",
                "Preview": "Visualização",
                "Images": "Imagens",
                "Unpublish": "Cancelar publicação",
                "Publish": "Publicar",
                "No": "Não",
                "Yes": "Sim",
                "Save": "Guardar",
                "Proceed": "Continuar",
                "Cancel": "Cancelar",
                "Paypal": "PayPal",
                "Bank Transfer": "Transferência Bancária",
                "UPI": "UPI",
                "Select Type": "Selecione o tipo",
                "Payout Request": "Pedido de pagamento",
                "Welcome to Your Real Estate Website": "Bem-vindo ao seu site imobiliário",
                "Submit Review": "Enviar avaliação",
                "Maybe Later": "Talvez mais tarde",
                "Write Your Review": "Escreva a sua avaliação",
                "How was your experience": "Como foi a sua experiência",
                "Leave A Review": "Deixe um comentário",
                "Drop Review": "Revisão de queda",
                "Confirm": "Confirmar",
                "Cancel Booking": "Cancelar reserva",
                "Note": "Nota",
                "Total": "Total",
                "Wallet Amount": "Valor da carteira",
                "Tax": "Imposto",
                "Sub Total": "Subtotal",
                "Property Price": "Preço do imóvel",
                "Price Section": "Secção de preços",
                "Transaction Id": "ID da transação",
                "Booking Status": "Estado da reserva",
                "Payment Status": "Estado de pagamento",
                "Payment Title": "Título de pagamento",
                "Phone Number": "Número de telefone",
                "Name": "Nome",
                "Booking Details": "Detalhes da reserva",
                "CheckOut": "Confira",
                "CheckIn": "Check-in",
                "Date&Time": "Data e hora",
                "Log in": "Conecte-se",
                "Have an account": "Tenha uma conta",
                "Register": "Registar",
                "I agree with terms & conditions": "Concordo com os termos e condições",
                "Create an account": "Criar uma conta",
                "Register here": "Registe-se aqui",
                "Not a member": "Não é membro",
                "Login": "Conecte-se",
                "Lost your password": "Perdeu a sua senha",
                "Remember me": "Lembre-se de mim",
                "Sign into your account": "Faça login na sua conta",
                "Reset": "Reiniciar",
                "Verify OTP": "Verifique OTP",
                "Check Number": "Verifique o número",
                "Reset Password Now": "Redefinir a palavra-passe agora",
                "Resend New Code": "Reenviar novo código",
                "Didn't receive code": "Não recebi o código",
                "Enter Your Otp Value": "Introduza o seu valor Otp",
                "Reset Your Password": "Redefinir a sua palavra-passe",
                "Welcome to Your Real Estate Website": "Bem-vindo ao seu site imobiliário",
                "Select Payment Method": "Selecione o método de pagamento",
                "Continue": "Continuar",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                "Choose the right pricing plan for you": "Escolha o plano de preços certo para si",
                "Cancel": "Cancelar",
                "Confirm": "Confirmar",
                "Cancel Booking Request": "Cancelar pedido de reserva",
                "Others": "Outros",
                "Personal Reasons": "Razões pessoais",
                "Competing offer": "Oferta concorrente",
                "Seller changes their mind": "Vendedor muda de ideias",
                "Title issues": "Problemas de título",
                "Change in financial situation": "Alteração da situação financeira",
                "Inspection issues": "Problemas de inspeção",
                "Financing fell through": "O financiamento falhou",
                "Save Profile": "Guardar perfil",
                "Number": "Número",
                "Email": "E-mail",
                "Old Password": "Senha Antiga",
                "Username": "Nome de utilizador",
                "Max file size is 1MB, Minimum dimension: 330x300, and Suitable files are .jpg & .png": "O tamanho máximo do ficheiro é de 1 MB, dimensão mínima: 330x300 e os ficheiros adequados são . jpg e .",
                "Profile Information": "Informações do perfil",
                "Select Payment Method": "Selecione o método de pagamento",
                "Add Amount": "Adicionar valor",
                "My Payout": "O meu pagamento",
                "No favorites found": "Nenhum favorito encontrado",
                "Action": "Ação",
                "Sqrft": "Pé quadrado",
                "Rate": "Taxa",
                "Listing Title": "Título da listagem",
                "we glade to see you again!": "estamos felizes por vê-lo novamente!",
                "Message": "Mensagem",
                "Days": "Dias",
                "Amount": "Montante",
                "Package": "Pacote",
                "Order ID": "ID do pedido",
                "Your Current Wallet Balance is": "O seu saldo atual da Carteira virtual é",
                "Notification": "Notificação",
                "on your wallet ": "na sua carteira",
                "You get": "Você consegue",
                "You Got": "Você tem",
                "complate transaction": "transação de compensação",
                "on their  first": "no seu primeiro",
                "Friends get": "Os amigos recebem",
                "Friend's Referral Bonus": "Bónus de recomendação de amigo",
                "with your friends": "com os seus amigos",
                "Share the referral link": "Partilhar o link de referência",
                "Share referral link": "Partilhar link de referência",
                "Each Friend you refer": "Cada amigo que indicar",
                "Cancel": "Cancelar",
                "Receipt": "Recibo",
                "Total Days": "Total de dias",
                "Status": "Estatuto",
                "P_Status": "P_Estado",
                "Earn ": "Ganhar",
                "Available coupons": "Cupões disponíveis",
                "Proceed to Checkout": "Fazer o check-out",
                "Total Payble Amount": "Valor total a pagar",
                "Tax": "Imposto",
                "Amount": "Montante",
                "Cart Totals": "Totais do carrinho",
                "Number of Guest": "Número de convidados",
                "Booking Date": "Data da reserva",
                "Booking Details": "Detalhes da reserva",
                "Available for Payment": "Disponível para pagamento",
                "Your Balance": "O seu saldo",
                "Apply Coupon": "Aplicar cupão",
                "Subtotal": "Subtotal",
                "Booking Days": "Dias de reserva",
                "Price": "Preço",
                "FOR": "PARA",
                "Product": "Produto",
                "Submit a Tour Request": "Envie um pedido de passeio",
                "optional": "opcional",
                "Note to Owner": "Nota para o proprietário",
                "Phone": "Telefone",
                "FeMale": "Fêmea",
                "Male": "Macho",
                "Please Select Gender": "Selecione o género",
                "Last Name": "Último nome",
                "First Name": "Nome próprio",
                "Book For Other": "Reserve para outro",
                "In Person": "Pessoalmente",
                "Guest": "Convidado",
                "Allowed Max ": "Máximo permitido",
                "Number Of Guest": "Número de convidados",
                "Schedule a tour": "Agende um tour",
                "Select a Date": "Selecione uma data",
                "City": "Cidade",
                "Details": "Detalhes",
                "Property Size": "Tamanho da propriedade",
                "Property Type": "Tipo de propriedade",
                "Property Status": "Situação da propriedade",
                "Facility Details": "Detalhes da instalação",
                "Contact Information": "Informações de contacto",
                "Call To Agent": "Ligue para o agente",
                "Reviews": "Avaliações",
                "Quick Links": "Links rápidos",
                "Bathrooms": "Banheiros",
                "Map": "Mapa",
                "Bedrooms": "Quartos",
                "Address": "Morada",
                "Contacted": "Contatado",
                "Photos": "Fotos",
                "Favorite": "Favorito",
                "Description": "Descrição",
                "Check Out": "Confira",
                "Check In": "Check-in",
                "Review Summary": "Resumo da revisão",
                "Recent Transactions": "Transações recentes",
                "Payment Mode": "Modo de pagamento",
                "Active": "Ativo",
                "Book Id": "ID do livro",
                "Booking Schedule": "Cronograma de Reservas",
                "Payment Method": "Método de pagamento",
                "Contact a Property Owner": "Entre em contacto com o proprietário do imóvel",

            }
        },

    },
    keySeparator: false,
    interpolation: { escapeValue: false }
})

export default i18n;