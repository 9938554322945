import React, { useEffect, useState } from 'react';
import { useContextex } from '../context/useContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';

function SenangpayPayment({ product_amount }) {
  const [paymentData, setPaymentData] = useState({
    email: 'text@gmail.co',
    phone: '911111111111',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePayment = () => {
    const paymentParams = {
      merchant_id: '635160135332883',
      payment_id: Date.now(),
      amount: product_amount,
      currency: 'EUR', 
      email: paymentData.email,
      phone: paymentData.phone,
      redirect_url: `${window.location.origin}`, 
      return_url: `${window.location.origin}`, 
      hash: generateHash('2909-249', product_amount, 'EUR')
    };

    const queryString = new URLSearchParams(paymentParams).toString();
    window.location.href = `https://sandbox.senangpay.my/payment/${paymentParams.merchant_id}?${queryString}`;
  };

  const generateHash = (apiKey, amount, currency) => {
    return btoa(`${apiKey}:${amount}:${currency}`);
  };

  useEffect(() => {
    handlePayment()
  },[])

  return null
}

export default SenangpayPayment;
