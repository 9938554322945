import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useContextex } from '../context/useContext';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AddRemoveToFav, fetchBookedDate, fetchData, fetchEnquiryProduct, fetchFavProdList, fetchProductGallaryData, fetchPropertyDetails } from '../store/action';
import GoogleMapReact from 'google-map-react';
import { IconHeart, IconHeartFilled, IconMapPinFilled } from '@tabler/icons-react';
import $ from 'jquery'

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Rating from '@mui/material/Rating';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Footer from '../partials/Footer';
import InqueryPage from './InqueryPage';
import { getAddRemoveFav, getBookedDate, getData, getIsFavorite, getLoading, getProdGallaryData, getUserFavListData } from '../store/selector';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';

const iconStyle = {
    color: 'red',
};

const Marker = () => (
    <div >
        <IconMapPinFilled style={iconStyle} />
    </div>
);

export const ProductDetails = () => {
    const [categoriesList, setCategoriesList] = useState([]);
    const [productImage, setProductImage] = useState([]);
    const [countGuest, setCountGuest] = useState(1);
    const [activeTab, setActiveTab] = useState('In Person')
    const [isLikedProd, setIsLikedProd] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [sendEReq, setSendEReq] = useState(false)
    const [gallaryData, setGallaryData] = useState([])
    const [notAvailableDate, setNotAvailableDate] = useState(null)
    const [activeGal, setActiveGal] = useState(0);
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [galLightboxOpen, setGalLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentGalImageIndex, setCurrentGalImageIndex] = useState(0);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [otherBookData, setOtherBookData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })

    const { productDetailId, setProductDetailId, setBookedProductData, imageBaseUrl, setCurrentPage, isUserId, token, selectedId, selectedCountryId, userCurrency, setBookedUserData, setBookedOtherUserData, bookedUserData, bookedOtherUserData, setOtherUserGender } = useContextex();
    const { t } = useTranslation();

    const proid = localStorage.getItem('pid')
    const pid = useParams()
    const formatedText = localStorage.getItem('formattedText')

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const data = useSelector(getData);
    const isFavorite = useSelector(getIsFavorite);
    const loading = useSelector(getLoading);
    const bookedDate = useSelector(getBookedDate);
    const prodGallary = useSelector(getProdGallaryData);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchBookedDate({ pro_id: productDetailId }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchDataAndSetCategories();
    }, [dispatch, productDetailId]);

    useEffect(() => {
        if (!productDetailId && pid?.pid == formatedText) {
            setProductDetailId(proid)
        }
    }, [productDetailId, formatedText])

    useEffect(() => {
        if (bookedDate && bookedDate.datelist) {
            setNotAvailableDate(bookedDate.datelist);
        }
    }, [bookedDate]);

    useEffect(() => {
        setCurrentPage('productDetails')
    }, [setCurrentPage]);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchProductGallaryData({ prop_id: productDetailId }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, productDetailId]);

    useEffect(() => {
        if (prodGallary) {
            setGallaryData(prodGallary?.gallerydata);
        }
    }, [prodGallary]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchFavProdList({ uid: isUserId, property_type: selectedId, country_id: selectedCountryId }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch]);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchPropertyDetails({ uid: isUserId || '0', pro_id: productDetailId }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, sendEReq]);

    useEffect(() => {
        if (data) {
            setCategoriesList(data);
            setProductImage(categoriesList?.propetydetails?.image);
            setBookedProductData(categoriesList);
        } else {
            console.error("Data or product is null or undefined.");
        }
    }, [data, categoriesList, sendEReq]);

    useEffect(() => {
        var tabs = function () {
            $('.widget-tabs').each(function () {
                $(this).find('.widget-content-tab').children().hide();
                $(this).find('.widget-content-tab').children(".active").show();
                $(this).find('.widget-menu-tab').children('.item-title').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive);
                    contentActive.addClass('active').fadeIn("slow");
                    contentActive.siblings().removeClass('active');
                    $(this).addClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive).siblings().hide();
                });
            });
            $('.widget-tabs-1').each(function () {
                $(this).find('.widget-content-tab-1').children().hide();
                $(this).find('.widget-content-tab-1').children(".active-1").show();
                $(this).find('.widget-menu-tab-1').children('.item-title-1').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive);
                    contentActive.addClass('active-1').fadeIn("slow");
                    contentActive.siblings().removeClass('active-1');
                    $(this).addClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive).siblings().hide();
                });
            });
        };

        return tabs()
    }, [])

    useEffect(() => {
        dispatch(fetchFavProdList({ uid: isUserId, property_type: selectedId, country_id: selectedCountryId }));
        dispatch(fetchPropertyDetails({ uid: isUserId || 0, pro_id: productDetailId }));
    }, [dispatch, isUserId, selectedId, selectedCountryId, productDetailId]);

    const userFavList = useSelector(getUserFavListData);
    const isAFavorite = useSelector(getAddRemoveFav);
    const today = new Date()

    useEffect(() => {
        const newIsLikedProduct = userFavList?.propetylist?.some(property => property?.id === productDetailId);
        setIsLikedProd(newIsLikedProduct);
    }, [userFavList, productDetailId]);

    const imagelist = categoriesList?.propetydetails?.image || [];

    let sources = [];
    if (categoriesList?.propetydetails?.image[0]?.image) {
        sources.push(`${imageBaseUrl}${categoriesList.propetydetails.image[0].image}`);
    }
    if (categoriesList?.propetydetails?.image[1]?.image) {
        sources.push(`${imageBaseUrl}${categoriesList.propetydetails.image[1].image}`);
    }
    if (imagelist.length > 0) {
        sources = sources.concat(imagelist.map(item => `${imageBaseUrl}${item?.image}`));
    }

    const isValidIndex = activeGal >= 0 && activeGal < gallaryData?.length;
    const activeCategory = isValidIndex ? gallaryData[activeGal] : {};
    const filteredImages = (activeCategory.imglist || []).map(img => `${imageBaseUrl}${img}`);
    // const filteredImages = gallaryData[activeGal]?.imglist?.map(img => `${imageBaseUrl}${img}`) || [];

    const openLightboxOnSlide = (index) => {
        setCurrentImageIndex(index - 1);
        setLightboxOpen(true);
    };

    const handleAddToFavorites = () => {
        dispatch(AddRemoveToFav({ uid: isUserId, pid: productDetailId, property_type: categoriesList?.propetydetails?.property_type }));
        setIsLikedProd(prevIsLiked => !prevIsLiked);
        setShowSnackbar(true)
    };

    const latitude = Number(categoriesList?.propetydetails?.latitude) || 21.2418403;
    const longitude = Number(categoriesList?.propetydetails?.longitude) || 72.8806;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOtherBookData({
            ...otherBookData,
            [name]: value
        });
        setBookedOtherUserData({
            ...bookedOtherUserData,
            [name]: value
        });
    };

    const updateUserData = async () => {
        const formattedCheckIn = selectionRange?.startDate?.toISOString().slice(0, 10);
        const formattedCheckOut = selectionRange?.endDate?.toISOString().slice(0, 10);
        const formattedCurrentDate = today.toISOString().slice(0, 10);

        const date1 = new Date(formattedCheckIn);
        const date2 = new Date(formattedCheckOut);

        // Adjusting dates by adding 1 day
        date1.setDate(date1.getDate() + 1);
        date2.setDate(date2.getDate() + 1);

        const adjustedCheckIn = date1.toISOString().slice(0, 10);
        const adjustedCheckOut = date2.toISOString().slice(0, 10);

        const differenceMs = Math.abs(date2 - date1);
        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24)) + 1;

        try {

            await setBookedUserData({
                bookingDate: formattedCurrentDate,
                checkIn: adjustedCheckIn,
                checkOut: adjustedCheckOut,
                noGuest: countGuest,
                days: differenceDays,
                notes: noteText,
                bookedFor: activeTab === 'In Person' ? 'self' : 'other',
            });
            await localStorage.setItem('bookinUserData', JSON.stringify(bookedUserData))
            await localStorage.setItem('check_out',adjustedCheckOut)
            await localStorage.setItem('days',differenceDays)

        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    useEffect(() => {
        updateUserData();
    }, [selectionRange, countGuest, noteText, activeTab,]);

    const defaultProps = {
        center: {
            lat: latitude,
            lng: longitude,
        },
        zoom: 11,
    };

    const availableBads = categoriesList?.propetydetails?.beds;

    const handleSelectGender = (event) => {
        setOtherUserGender(event.target.getAttribute('data-value'));
    };

    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
    };

    const getDatesBetween = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        while (currentDate <= end) {
            dates.push(currentDate.toISOString().slice(0, 10));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const getAllDates = () => {
        const allDates = [];
        notAvailableDate?.forEach(item => {
            const datesBetween = getDatesBetween(item.check_in, item.check_out);
            allDates.push(...datesBetween);
        });
        return allDates;
    };

    const allDates = getAllDates();
    const disabledDates = allDates?.map(dateStr => new Date(dateStr));

    const allDisabledDates = [...disabledDates];

    const decreaseCount = () => {
        if (countGuest > 1) {
            setCountGuest(prevCount => prevCount - 1);
        }
    };

    const increaseCount = () => {
        if (countGuest < availableBads) {
            setCountGuest(prevCount => prevCount + 1);
        }
    };

    const handleSubmit = () => {
        console.log(bookedUserData)
        console.log(bookedOtherUserData)
    }

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const openGalLightbox = (index) => {
        setCurrentGalImageIndex(index);
        setGalLightboxOpen(true);
    };

    const validateInputs = () => {
        const newErrors = { firstName: '', lastName: '', phone: '', email: '' };
        let isValid = true;

        if (!bookedOtherUserData.firstName) {
            newErrors.firstName = "Please Enter Your FirstName";
            isValid = false;
        }
        if (!bookedOtherUserData.lastName) {
            newErrors.lastName = "Please Enter Your LastName";
            isValid = false;
        }
        if (!bookedOtherUserData.email) {
            newErrors.email = "Please Enter Your Email";
            isValid = false;
        }
        if (!bookedOtherUserData.phone) {
            newErrors.phone = "Phone number can't be null";
            isValid = false;
        }

        console.log(newErrors);
        return isValid;
    };

    const handleBook = () => {
        if (activeTab === 'Book For Other') {
            if (!validateInputs()) return;
            setOtherBookData('')
            navigate('/product-cart')
        } else if (activeTab === 'In Person') {
            navigate('/product-cart')
        }
    }

    return (
        <>
            {loading && (
                <div className="preload preload-container" >
                    <div className="middle"></div>
                </div>
            )}

            <div className="main-content">

                <div className="property-single-wrap sticky-container" data-sticky-container>
                    <div className="cl-container">

                        <div className="row">

                            <div className="col-12">
                                <div className="flex items-center justify-between gap30 flex-wrap pt-30 pb-30">

                                    <ul className="breadcrumbs style-1 justify-start">
                                        <li><a className='pointer' onClick={() => navigate('/')} >Home</a></li>
                                        <li>/</li>
                                        <li className='pointer' onClick={() => navigate('/product-all')}>Property List</li>
                                        <li>/</li>
                                        <li>{categoriesList?.propetydetails?.title}</li>
                                    </ul>

                                    {isUserId && token && (
                                        <div className="list-icons-page">
                                            <div className="item">
                                                <div className="icon" onClick={handleAddToFavorites}>
                                                    {isLikedProd ?
                                                        <IconHeartFilled style={iconStyle} /> :
                                                        <IconHeart style={iconStyle} />
                                                    }
                                                </div>
                                                <p>{t('Favorite')}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="wrap-gallery-image">
                                    <div className="list-tags type-1">
                                        <a
                                            className="tags-item for-sell pointer"
                                            onClick={() => openLightboxOnSlide(0)}
                                        >
                                            {categoriesList?.propetydetails?.buyorrent == '2' ? 'Buy' : 'Rent'}
                                        </a>
                                    </div>
                                    <a
                                        className="item-1 pointer"
                                        onClick={() => openLightboxOnSlide(1)}
                                    >
                                        <img
                                            src={`${imageBaseUrl}${categoriesList?.propetydetails?.image[0]?.image}`}
                                            alt="Property"
                                        />
                                    </a>
                                    {imagelist?.map((item, index) => (
                                        <a
                                            key={index}
                                            className="pointer"
                                            onClick={() => openLightboxOnSlide(index + 3)}
                                        >
                                            <img
                                                src={`${imageBaseUrl}${item?.image}`}
                                                alt={`item-${index}`}
                                            />
                                        </a>
                                    ))}
                                    <a
                                        className="more-photos pointer"
                                        onClick={() => openLightboxOnSlide(sources.length)}
                                    >
                                        <i className="flaticon-gallery"></i>
                                        <p>{t('Photos')}</p>
                                    </a>
                                </div>
                                {lightboxOpen && (
                                    <Lightbox
                                        mainSrc={sources[currentImageIndex]}
                                        nextSrc={sources[(currentImageIndex + 1) % sources.length]}
                                        prevSrc={sources[(currentImageIndex + sources.length - 1) % sources.length]}
                                        onCloseRequest={() => setLightboxOpen(false)}
                                        onMovePrevRequest={() => setCurrentImageIndex((currentImageIndex + sources.length - 1) % sources.length)}
                                        onMoveNextRequest={() => setCurrentImageIndex((currentImageIndex + 1) % sources.length)}
                                    />
                                )}
                            </div>

                            <div className="col-xl-8">
                                <div className="content-wrap">

                                    <div className="head-title wow fadeInUp">
                                        <div>
                                            <h3>{categoriesList?.propetydetails?.title}</h3>
                                            <div className="location">
                                                <div className="icon">
                                                    <i className="flaticon-location"></i>
                                                </div>
                                                <div className="text-content">{categoriesList?.propetydetails?.city}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="square">{categoriesList?.propetydetails?.sqrft} /sq ft</div>
                                            <div className="price">{userCurrency}{categoriesList?.propetydetails?.price}</div>
                                        </div>
                                    </div>

                                    <div className="box-items">
                                        <div className="item wow fadeInUp">
                                            <div className="icon">
                                                <i className="flaticon-hotel"></i>
                                            </div>
                                            <div className="text-content">{availableBads} {t('Bedrooms')}</div>
                                        </div>
                                        <div className="item wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="icon">
                                                <i className="flaticon-bath-tub"></i>
                                            </div>
                                            <div className="text-content">{categoriesList?.propetydetails?.bathroom} {t('Bathrooms')}</div>
                                        </div>
                                        <div className="item wow fadeInUp" data-wow-delay="0.2s">
                                            <div className="icon">
                                                <i className="flaticon-minus-front"></i>
                                            </div>
                                            <div className="text-content">{categoriesList?.propetydetails?.sqrft} Sq Ft</div>
                                        </div>
                                    </div>

                                    <div className="desc">
                                        <h4 className="wow fadeInUp">{t('Description')}</h4>
                                        <p className="wow fadeInUp">
                                            {categoriesList?.propetydetails?.description}
                                        </p>
                                    </div>

                                    <div className="address">
                                        <div className="list-item">
                                            <div className="item wow fadeInUp">
                                                <div className="text">{t('Address')}</div>
                                                <p>{categoriesList?.propetydetails?.address}</p>
                                            </div>
                                            <div className="item wow fadeInUp">
                                                <div className="text">{t('City')}</div>
                                                <p>{categoriesList?.propetydetails?.city}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="details">
                                        <h4 className="wow fadeInUp">{t('Details')}</h4>
                                        <div className="list-item">

                                            <div className="item wow fadeInUp">
                                                <div className="text">{t('Price')}:</div>
                                                <p>{userCurrency}{categoriesList?.propetydetails?.price}</p>
                                            </div>
                                            <div className="item wow fadeInUp">
                                                <div className="text">{t('Property Size')}:</div>
                                                <p>{categoriesList?.propetydetails?.sqrft} Sq Ft</p>
                                            </div>
                                            <div className="item wow fadeInUp">
                                                <div className="text">{t('Bedrooms')}:</div>
                                                <p>{availableBads}</p>
                                            </div>
                                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="text">{t('Property Type')}:</div>
                                                <p>{categoriesList?.propetydetails?.property_title}</p>
                                            </div>
                                            <div className="item wow fadeInUp">
                                                <div className="text">{t('Bathrooms')}:</div>
                                                <p>{categoriesList?.propetydetails?.bathroom} </p>
                                            </div>
                                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="text">{t('Property Status')}:</div>
                                                <p>{`For ${categoriesList?.propetydetails?.buyorrent == '2' ? 'Buy' : 'Sale'}`}</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="features">
                                        <ul>
                                            <li>
                                                <h5 className="wow fadeInUp">{t('Facility Details')}</h5>
                                                <div className="wrap-check-ellipse wow fadeInUp mobile-facility" data-wow-delay="0.1s">
                                                    {categoriesList?.facility?.map((itm, index) => (

                                                        <div className="plans py-0 my-0" key={index}>
                                                            <div className="widget-tabs style-3">
                                                                <div className="widget-content-tab">
                                                                    <div className="widget-content-inner active">
                                                                        <div className="icons">
                                                                            <div className="item d-flex flex-column  align-items-center justify-content-center wow fadeInUp">
                                                                                <img className='mt-0' src={`${imageBaseUrl}${itm?.img}`} alt={itm?.title} />
                                                                                <div className="text">{itm?.title}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))}

                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="plans">
                                        {gallaryData?.length > 0 && (
                                            <>
                                                <h4 className="wow fadeInUp">Gallery</h4>

                                                <div className="widget-tabs style-3">
                                                    <ul className="widget-menu-tab wow fadeInUp">
                                                        {gallaryData
                                                            ?.filter(item => item?.imglist && item?.imglist?.length > 0)
                                                            ?.map((item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className={`item-title ${index === activeGal ? 'active' : ''}`}
                                                                    onClick={() => setActiveGal(index)}
                                                                >
                                                                    <span className="inner">{item.title}</span>
                                                                </li>
                                                            ))}
                                                    </ul>

                                                    <div className="widget-content-tab">
                                                        <div className={`widget-content-inner ${filteredImages.length > 0 ? 'active' : ''}`}>
                                                            <div className="icons">
                                                                {filteredImages?.map((img, imgIndex) => (
                                                                    <div
                                                                        className=" wow fadeInUp m-0 p-0"
                                                                        style={{ minHeight: '200px', maxHeight: '200px', minWidth: '200px', maxWidth: '200px' }}
                                                                        key={imgIndex}
                                                                        onClick={() => openGalLightbox(imgIndex)}
                                                                    >
                                                                        <img
                                                                            className="w-100 p-0 m-0"
                                                                            style={{ borderRadius: '15px' }}
                                                                            src={img}
                                                                            alt={`Gallery image ${imgIndex + 1}`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        )}

                                        {galLightboxOpen && filteredImages.length > 0 && (
                                            <Lightbox
                                                mainSrc={filteredImages[currentGalImageIndex]}
                                                nextSrc={filteredImages[(currentGalImageIndex + 1) % filteredImages.length]}
                                                prevSrc={filteredImages[(currentGalImageIndex + filteredImages.length - 1) % filteredImages.length]}
                                                onCloseRequest={() => setGalLightboxOpen(false)}
                                                onMovePrevRequest={() =>
                                                    setCurrentGalImageIndex((currentGalImageIndex + filteredImages.length - 1) % filteredImages.length)
                                                }
                                                onMoveNextRequest={() =>
                                                    setCurrentGalImageIndex((currentGalImageIndex + 1) % filteredImages.length)
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="contact-info">
                                        <div className="flex items-center justify-between gap30 flex-wrap wow fadeInUp">
                                            <h4 className="mb-0">{t('Contact Information')}</h4>
                                            <a className="tf-button-green pointer" href={`tel:${categoriesList?.propetydetails?.mobile}`}>{t('Call To Agent')}</a>
                                        </div>
                                        <div className="person wow fadeInUp">
                                            <div className="image">
                                                <img src={`${imageBaseUrl}${categoriesList?.propetydetails?.owner_image}`} alt="" />

                                            </div>
                                            <div className="content">
                                                <div className="name">
                                                    <a>{categoriesList?.propetydetails?.owner_name}</a>
                                                </div>
                                                {isUserId && token && (
                                                    <p><a href={`tel:${categoriesList?.propetydetails?.mobile}`}> {categoriesList?.propetydetails?.mobile}</a></p>
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="map">
                                        <h4 className="wow fadeInUp">{t('Map')}</h4>
                                        <div className="wrap-map-v1">
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: "AIzaSyA2v7oamtvcDZ7IOCc3ZKZcew4fDlGBgBU" }}
                                                defaultCenter={defaultProps.center}
                                                defaultZoom={defaultProps.zoom}
                                                draggable={false}
                                                options={{ gestureHandling: 'none' }}
                                                goToRangeStartOnSelect
                                            >
                                                <Marker
                                                    lat={defaultProps.center.lat}
                                                    lng={defaultProps.center.lng}
                                                    text='My Pin'
                                                />
                                            </GoogleMapReact>
                                        </div>
                                    </div>

                                    {categoriesList?.total_review > 0 && (
                                        <div className="reviews-wrap">
                                            <div className="flex justify-between items-center mb-40 wow fadeInUp">
                                                <h4 className="mb-0"> {t('Reviews')}</h4>
                                                <div className='d-flex align-items-center'>
                                                    <Rating
                                                        name="half-rating-read"
                                                        defaultValue={3}
                                                        value={categoriesList?.propetydetails?.rate}
                                                        precision={0.5}
                                                        readOnly
                                                    />
                                                    <p>{categoriesList?.propetydetails?.rate} ({categoriesList?.total_review} reviews)</p>
                                                </div>
                                            </div>
                                            <ul>
                                                {categoriesList?.reviewlist?.map((item, index) => (
                                                    <li className="wow fadeInUp" key={index}>
                                                        <div className="image">
                                                            {item?.user_img ? (
                                                                <img className='h-100' src={`${imageBaseUrl}${item?.user_img}`} alt="" />
                                                            ) : (
                                                                <img src="../assets/icon/profile-default.png" alt="default profile" />
                                                            )}
                                                        </div>
                                                        <div className="content">
                                                            <div className="ratings">
                                                                <Rating
                                                                    name="half-rating-read"
                                                                    defaultValue={0}
                                                                    value={item?.user_rate}
                                                                    precision={0.5}
                                                                    readOnly
                                                                />
                                                            </div>
                                                            <div className="name">
                                                                <a >{item?.user_title}</a>
                                                            </div>
                                                            <p>{item?.user_desc}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                </div>
                            </div>

                            {isUserId && token && (

                                <div className="col-xl-4">
                                    {categoriesList?.propetydetails?.buyorrent == '1' && (
                                        <>
                                            <div className="property-single-sidebar po-sticky my-4">
                                                <div className="sidebar-item sidebar-request">
                                                    <div className="schedule">
                                                        <h4 className="wow fadeInUp">{t('Select a Date')}</h4>
                                                        <fieldset className="message my-2 col-12 ">

                                                            <div>
                                                                <DateRange
                                                                    ranges={[selectionRange]}
                                                                    minDate={today}
                                                                    onChange={handleSelect}
                                                                    disabledDates={allDisabledDates}
                                                                />
                                                                <div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="property-single-sidebar po-sticky">
                                                <div className="sidebar-item sidebar-request">
                                                    <div className="schedule">
                                                        <h4 className="wow fadeInUp">{t('Schedule a tour')}</h4>
                                                        <form className="form-schedule">
                                                            <div className='w-100'>
                                                                <fieldset className="name my-4  has-top-title col-12">
                                                                    <p>{t('Check In')}</p>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Check In"
                                                                        className=""
                                                                        tabIndex="2"
                                                                        value={selectionRange.startDate.toDateString()}
                                                                        aria-required="true"
                                                                        disabled
                                                                    />
                                                                </fieldset>
                                                                <fieldset className="name my-4 has-top-title col-12">
                                                                    <p>{t('Check Out')}</p>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Check In"
                                                                        className=""
                                                                        tabIndex="2"
                                                                        value={selectionRange.endDate.toDateString()}
                                                                        aria-required="true"
                                                                        disabled
                                                                    />
                                                                </fieldset>
                                                            </div>

                                                            <div className="flex gap20 col-12">
                                                                <div className='col-6'>
                                                                    <h5>{t('Number Of Guest')}</h5>
                                                                    <p>{t('Allowed Max')} {availableBads} {t('Guest')}</p>
                                                                </div>
                                                                <div className="wg-quantity">
                                                                    <p
                                                                        className="p-0 btn-quantity pointer minus-btn"
                                                                        disabled={countGuest === 1} onClick={decreaseCount}
                                                                    >
                                                                        -
                                                                    </p>
                                                                    <input
                                                                        type="text"
                                                                        name="number"
                                                                        value={countGuest}
                                                                        readOnly
                                                                    />
                                                                    <p
                                                                        className="p-0 btn-quantity pointer plus-btn"
                                                                        disabled={countGuest === availableBads} onClick={increaseCount}
                                                                    >
                                                                        +
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="widget-tabs style-4">
                                                                <ul className="widget-menu-tab">

                                                                    <li className={`item-title ${activeTab === 'In Person' && 'active'}`}>
                                                                        <span className="inner" onClick={() => setActiveTab('In Person')}>{t('In Person')}</span>
                                                                    </li>

                                                                    <li className={`item-title ${activeTab === 'Book For Other' && 'active'}`}>
                                                                        <span className="inner" onClick={() => setActiveTab('Book For Other')}>{t('Book For Other')}</span>
                                                                    </li>

                                                                </ul>

                                                                <div className="widget-content-tab">
                                                                    <div className="widget-content-inner active">
                                                                        {activeTab === 'Book For Other' && (

                                                                            <div>
                                                                                <fieldset className="name my-4 has-top-title col-12">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="First Name"
                                                                                        className=""
                                                                                        name="firstName"
                                                                                        tabIndex="2"
                                                                                        value={otherBookData.firstName}
                                                                                        aria-required="true"
                                                                                        required
                                                                                        onChange={handleInputChange}
                                                                                    />
                                                                                    <label htmlFor="">{t('First Name')}</label>
                                                                                </fieldset>

                                                                                <fieldset className="name my-4 has-top-title col-12">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Last Name"
                                                                                        className=""
                                                                                        name="lastName"
                                                                                        tabIndex="2"
                                                                                        value={otherBookData.lastName}
                                                                                        aria-required="true"
                                                                                        required
                                                                                        onChange={handleInputChange}
                                                                                    />
                                                                                    <label htmlFor="">{t('Last Name')}</label>
                                                                                </fieldset>

                                                                                <div className="nice-select wow fadeInUp my-4" data-wow-delay="0.1s" tabindex="0">
                                                                                    <span className="current">{t('Please Select Gender')}</span>
                                                                                    <ul className="list">
                                                                                        <li data-value="male" className="option" onClick={handleSelectGender}>{t('Male')}</li>
                                                                                        <li data-value="feMale" className="option" onClick={handleSelectGender}>{t('FeMale')}</li>
                                                                                    </ul>
                                                                                </div>

                                                                                <fieldset className="email has-top-title col-12">
                                                                                    <input
                                                                                        type="email"
                                                                                        placeholder="Email"
                                                                                        className=""
                                                                                        name="email"
                                                                                        tabIndex="2"
                                                                                        value={otherBookData.email}
                                                                                        aria-required="true"
                                                                                        required
                                                                                        onChange={handleInputChange}
                                                                                    />
                                                                                    <label htmlFor="">{t('Email')}</label>
                                                                                </fieldset>

                                                                                <fieldset className="phone my-5 has-top-title col-12">
                                                                                    <input
                                                                                        type="tel"
                                                                                        placeholder="Phone"
                                                                                        className=""
                                                                                        name="phone"
                                                                                        tabIndex="2"
                                                                                        value={otherBookData.phone}
                                                                                        aria-required="true"
                                                                                        required
                                                                                        onChange={handleInputChange}
                                                                                    />
                                                                                    <label htmlFor="">{t('Phone')}</label>
                                                                                </fieldset>

                                                                            </div>
                                                                        )}

                                                                        <fieldset className="message has-top-title">
                                                                            <textarea name="message" rows="4" placeholder="Notes" className="" tabindex="2" aria-required="true" defaultValue={noteText} onChange={(e) => setNoteText(e.target.value)}></textarea>
                                                                            <label for="">{t('Note to Owner')} ({t('optional')})</label>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <Link to={'/product-cart'}> */}
                                                            <div className="button-submit">
                                                                <button className="tf-button-primary w-full justify-content-center d-flex" onClick={handleBook} >{t('Continue')}<i className="icon-arrow-right-add"></i></button>
                                                            </div>
                                                            {/* </Link> */}

                                                        </form>

                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {categoriesList?.propetydetails?.buyorrent == '2' && (
                                        <div className="property-single-sidebar po-sticky my-4">
                                            {categoriesList?.propetydetails?.buyorrent == '2' && (
                                                <div className="sidebar-item sidebar-request">
                                                    {isUserId && token && (
                                                        <InqueryPage setSendEReq={setSendEReq} />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                </div>

                            )}

                        </div>

                    </div>
                </div>

            </div>
            <Footer />
            {showSnackbar && isAFavorite?.ResponseMsg && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={isAFavorite?.ResponseMsg} />}
        </>
    )
}