import React, { useEffect } from 'react';
import axios from 'axios';
import { uid } from 'uid';

export const MidtransPayment = ({ product_amount }) => {
  const handlePayment = () => {
    try {
      const token = uid(12); 

      window.snap.pay(token, {
        onSuccess: function(result) {
          console.log('Payment Success:', result);
          alert('Payment Success');
        },
        onPending: function(result) {
          console.log('Payment Pending:', result);
          alert('Payment Pending');
        },
        onError: function(result) {
          console.log('Payment Error:', result);
          alert('Payment Error');
        },
        onClose: function() {
          console.log('Payment Dialog Closed');
          alert('Payment Dialog Closed');
        }
      });
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  useEffect(() => {
    handlePayment();
  }, []);

  return null
};

