import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { packagePlanBuy } from '../store/action';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useContextex, usePayment } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';

export const usePackagePurchase = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);

    const {
        isUserId,
        transactionId,
        selectedPackData,
        selectedPaymentType,
        setTransactionId,
        setSelectedPaymentId,
        selectedPaymentId
    } = useContextex();

    const { payForPackage } = usePayment();

    const purchasePackage = useCallback(async () => {
        if (!isUserId || !selectedPackData?.id || !selectedPaymentType || !transactionId) {
            console.error('Required fields are missing for purchase.');
            return;
        }

        if(transactionExecutedRef.current){
            return;
        }
        setIsPurchasing(true);
        try {
            
            
            transactionExecutedRef.current = true

            await dispatch(packagePlanBuy({
                uid: isUserId,
                transaction_id: selectedPackData.price > 0 || ['2', '5'].includes(selectedPaymentId) ? transactionId : 0,
                plan_id: selectedPackData?.id ,
                pname: selectedPackData.price > 0 ? selectedPaymentType : 'test',
                wall_amt: '0',
            }));

        } catch (error) {
            console.error('Error purchasing package:', error);
        } finally {
            setIsPurchasing(false);
        }
    }, [transactionId]);

    const transactionExecutedRef = useRef(false);

    useEffect(() => {
        const handleTransaction = async () => {
            if (transactionId) {
                await purchasePackage();
                await payForPackage();
                navigate('/');
                setTransactionId('');
                setSelectedPaymentId('');
            }
        };

        handleTransaction();
    }, [transactionId]);

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            {showSnackbar && (
                <CustomSnackbar handleClose={handleCloseSnackbar} snack_message="Package Purchased Successfully" />
            )}
        </>
    );
};
