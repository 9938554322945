import React, { useEffect } from 'react';

export const MercadoPagoPayment = ({ product_amount, product_name }) => {
  useEffect(() => {
    const initializePayment = () => {
      const publicKey = 'TEST-47dbadaa-3722-4781-907b-e2e6d3548b54';
      const mp = new window.MercadoPago(publicKey, {
        locale: 'en-US',
      });

      const preference = {
        items: [
          {
            title: product_name,
            quantity: 1,
            currency_id: 'USD',
            unit_price: parseFloat(product_amount),
          },
        ],
        back_urls: {
          success: 'http://localhost:3000/success',
          failure: 'http://localhost:3000/failure',
          pending: 'http://localhost:3000/pending',
        },
        auto_return: 'approved',
      };

      const createPreference = async () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve('TEST-PREFERENCE-ID');
          }, 1000);
        });
      };

      createPreference().then((preferenceId) => {
        mp.checkout({
          preference: {
            id: preferenceId,
          },
          render: {
            container: '#payment-container',
            label: 'Pay with MercadoPago',
          },
        });
      }).catch(error => {
        console.error('Error creating preference:', error);
      });
    };

    initializePayment();
  }, [product_amount, product_name]);

  return null
};
