import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const PayfastPayment = ({ product_amount, product_name, booked_for }) => {

  const newAmount = Math.round(product_amount);
  const navigate = useNavigate();
  localStorage.setItem('bookFor',booked_for)

  const handleCheckout = () => {
    const paymentData = {
      merchant_id: '10000100',
      merchant_key: '46f0cd694581a',
      amount:newAmount,
      item_name: 'Test Item' || product_name,
      return_url: window.location.origin + "/payment_success",
      cancel_url: window.location.origin + '/package_cancel',
      notify_url: 'https://yournotificationurl.com',
    };

    const form = document.createElement('form');
    form.action = 'https://sandbox.payfast.co.za/eng/process';
    form.method = 'POST';
    
    Object.keys(paymentData).forEach(key => {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = paymentData[key];
      form.appendChild(hiddenField);
    });

    document.body.appendChild(form);
    form.submit()
  }

  useEffect(() => {
    if(newAmount){
      handleCheckout();
    }
  },[])

  return null

};

export default PayfastPayment;
