import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUHomeData } from '../store/selector';
import { useContextex } from '../context/useContext';
import { fetchUserData } from '../store/action';

export const usePropertyList = () => {
    const { setFeaturedPropList, isUserId, setPropertyType, selectedCountryId, setTabsList } = useContextex();
    const uHomeData = useSelector(getUHomeData);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchUserData({ uid: isUserId || '0', country_id: selectedCountryId || '0' }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchDataAndSetCategories();
    }, [dispatch, selectedCountryId, isUserId]);

    useEffect(() => {
        if (uHomeData) {
            setTabsList(uHomeData.HomeData?.Catlist);
            setFeaturedPropList(uHomeData?.HomeData?.Featured_Property);
            setPropertyType(uHomeData?.HomeData?.Catlist);
        }
    }, [uHomeData, setFeaturedPropList, setPropertyType]);

    return {
        setFeaturedPropList,
        setPropertyType,
        setTabsList
    };
};
