import React, { useEffect } from 'react';
import { uid } from 'uid';

export const PaytmPayment = ({ product_amount }) => {
  
  useEffect(() => {
    const handlePayment = () => {
      // Generate Paytm token and order ID - This would usually come from your backend
      const orderId = 'ORDER' + new Date().getTime();
      const txnToken = uid(12); // Replace with a real token from your backend
    
      // Create a form dynamically
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://securegw.paytm.in/theia/processTransaction';
    
      // Add required hidden fields
      const fields = [
        { name: 'MID', value: 'TxCado20711191599835' }, 
        { name: 'ORDER_ID', value: orderId },
        { name: 'TXN_AMOUNT', value: product_amount },
        { name: 'CUST_ID', value: 'CUST0001' },
        { name: 'CHANNEL_ID', value: 'WEB' },
        { name: 'WEBSITE', value: 'GoProperty' },
        { name: 'INDUSTRY_TYPE_ID', value: 'TEST' }, 
        { name: 'CALLBACK_URL', value: `https://localhost:3000/theia/paytmCallback?ORDER_ID=${orderId}` },
        { name: 'CHECKSUMHASH', value: txnToken }, 
      ];

      fields.forEach(field => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = field.name;
        input.value = field.value;
        form.appendChild(input);
      });
    
      document.body.appendChild(form);
      form.submit();
    };

    handlePayment();
  }, [product_amount]);

  return null; 
};
