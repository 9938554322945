import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const TwoCheckoutPayment = ({ product_amount }) => {
  const sellerId = '250507228545'; 
  const orderId = Date.now();
  const navigate = useNavigate();

  useEffect(() => {
    const handlePayment = () => {
      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', 'https://www.2checkout.com/checkout/purchase');

      const inputs = [
        { name: 'sid', value: sellerId },
        { name: 'amount', value: product_amount },
        { name: 'currency', value: 'USD' },
        { name: 'return_url', value: `${window.location.origin}` }, 
        { name: 'cancel_url', value: `${window.location.origin}` }, 
        { name: 'merchant_order_id', value: orderId }
      ];

      inputs.forEach(({ name, value }) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    };

    handlePayment();
  }, [product_amount, sellerId, orderId]);

  return null; 
};
