import React, { useEffect, useState, useCallback } from 'react';
import { useFlutterwave } from 'react-flutterwave';
import { uid } from 'uid';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import AddProparty from '../addProparty/AddProparty';
import { useBookProduct } from '../hooks/useBookProduct';
import { usePackagePurchase } from '../hooks/usePackagePurchase';

export const FlutterwavePayment = ({ product_amount, product_name, product_id, booked_for }) => {
    const { bookedOtherUserData, loginUserData, setTransactionId, isUserId, transactionId } = useContextex();
    const [paymentResponse,setPaymentResponse]= useState(null)
    const snackMessage = 'Your transaction was successful';
    const navigate = useNavigate();
    const { performBookProduct } = useBookProduct(booked_for={booked_for});
    const { performBookPackage } = usePackagePurchase();

    const config = {
        public_key: 'FLWPUBK_TEST-5760e3ff9888aa1ab5e5cd1ec3f99cb1-X',
        tx_ref: Date.now().toString(),
        amount: product_amount,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd,banktransfer',
        customer: {
            email: bookedOtherUserData?.email || loginUserData?.UserLogin?.email || 'test@gmail.com',
            phonenumber: bookedOtherUserData?.phone || loginUserData?.UserLogin?.mobile,
            name: bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name,
        },
        customizations: {
            title: product_name,
            description: 'Payment for items you want to order',
            logo: 'http://www.quatrixglobal.com/wp-content/uploads/2019/08/logo.png',
        },
        meta: {
            user_id: isUserId,
            order_id: uid(),
            product_id: product_id,
        },
    };

    const handlePayment = useFlutterwave(config);

    const handlePaymentCallback = useCallback(async (response) => {
        
        if (response.status === 'successful') {
            await setTransactionId(response?.transaction_id);
            if (booked_for === 'booking') {
                if(performBookProduct){
                    performBookProduct();
                }
            }  else if (booked_for === 'package') {
                if(transactionId){
                    await performBookPackage();
                }
            }  else if (booked_for === 'wallet') {
                if(transactionId){
                    navigate('/dashboard')
                }
            }
            await setPaymentResponse('successful')
        } else if (response.status === 'cancelled') {
            setPaymentResponse('cancelled')
            navigate('/payment-cancel');
        } else {
            setPaymentResponse('failed')
            console.error('Payment failed.');
        }
    }, [booked_for, performBookProduct, performBookPackage, setTransactionId, transactionId, navigate]);


    useEffect(() => {
        if(paymentResponse == 'successful'){
            navigate(0)
        }
    },[paymentResponse])

    useEffect(() => {
        if (handlePayment) {
            handlePayment({ callback: handlePaymentCallback });
        }

        return () => {
            if (handlePayment) {
                handlePayment({ callback: null });
            }
        };
    }, [handlePayment, handlePaymentCallback]);

    return (
        <>
            {booked_for === 'package' && transactionId && <AddProparty />}
        </>
    );
};
