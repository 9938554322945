import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl, fetchAddToProperty, fetchCountryData, fetchData, fetchPropertyFacilities } from '../store/action';
import { IconLocation } from '@tabler/icons-react';
import { useContextex } from '../context/useContext';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getAddPropertyData, getCountryListData, getData, getFacilityData } from '../store/selector';
import { Button } from 'bootstrap';
import axios from 'axios';
import { usePropertyList } from '../hooks/usePropertyList';

const SelectField = ({ label, options, selectedValue, onSelect }) => (
    <div className="nice-select" tabIndex="0">
        <span className="current">
            {selectedValue ? options?.find(option => option.value === selectedValue)?.label : label}
        </span>
        <ul className="list">
            {options?.map(option => (
                <li
                    key={option.value}
                    data-value={option.value}
                    className={selectedValue === option.value ? 'option selected' : 'option'}
                    onClick={onSelect}
                >
                    {option.label}
                </li>
            ))}
        </ul>
    </div>
);

// Sub-component for Text Field
const TextField = ({ label, name, value, onChange, disabled }) => (
    <fieldset className="text">
        <input
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={label}
            disabled={disabled}
            required
        />
    </fieldset>
);

// Sub-component for Text Area Field
const TextAreaField = ({ label, name, value, onChange }) => (
    <fieldset className="description has-top-title">
        <textarea
            name={name}
            value={value}
            onChange={onChange}
            placeholder={label}
            required
        ></textarea>
        <label>{label}</label>
    </fieldset>
);

// Sub-component for File Upload
const FileUpload = ({ selectedFile, onFileChange,defImg,imageBaseUrl }) => (
    <div className="col-sm-6">
        <div className="right">
            <label className="uploadfile w-100" style={{ float: 'left' }}>
                <div className="tf-button-primary w-100 style-bg-white">
                    {defImg && selectedFile ? (
                        <img
                            src={`${imageBaseUrl}${defImg}`}
                            alt="Selected"
                            style={{ width: '30px', height: '30px' }}
                            className='m-0'
                        />
                    ) : (
                        <img
                            src={`data:image/jpeg;base64,${selectedFile}`}
                            alt="Selected"
                            style={{ width: '30px', height: '30px' }}
                            className='m-0'
                        />
                    )}
                    <input type="file" accept='image/*' onChange={onFileChange} name="file" />
                </div>
            </label>
        </div>
    </div>
);

const AddPropartyForm = () => {
    const { t } = useTranslation();
    const {
        propertyType,
        countryData,
        imageBaseUrl,
        isUserId,
        isEditSelectedProperty,
        editSelectedProperty,
        setCountryData,
        selectedCountryId,
        setCountryListData
    } = useContextex();
    const countryList = useSelector(getCountryListData);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedPropertyType, setSelectedPropertyType] = useState('');
    const [selectedPropertyStatus, setSelectedPropertyStatus] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [propertyDetails, setPropertyDetails] = useState({
        prop_title: '',
        prop_address: '',
        mobile_number: '',
        prop_price: '',
        prop_description: '',
        prop_no_of_beds: '',
        prop_no_of_bath: '',
        prop_sqrt: '',
        prop_rating: '',
        prop_capacity: '',
        prop_city: '',
    });
    const [position, setPosition] = useState({
        latitude: null,
        longitude: null,
    });
    const [propertyFacilityData, setPropertyFacilityData] = useState([]);
    const [checkedFacilities, setCheckedFacilities] = useState([]);
    const [mappedFacilities, setMappedFacilities] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState('')
    const [defImg, setDefImg] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(getData);
    const facilitys = useSelector(state => state.facilities);
    const sendAddProperty = useSelector(getAddPropertyData);

    const { setFeaturedPropList, setPropertyType, setTabsList } = usePropertyList();

    useEffect(() => {
        if (isEditSelectedProperty && editSelectedProperty) {
            const { longtitude, latitude, city, plimit, rate, sqrft, bathroom, beds, description, price, mobile, address, title, image, status, property_type_id, country_id, buyorrent } = editSelectedProperty
            setSelectedProperty(buyorrent);
            setSelectedCountry(country_id);
            setSelectedPropertyType(property_type_id);
            setSelectedPropertyStatus(status);
            setSelectedFile('0');

            setPropertyDetails({
                prop_title: title,
                prop_address: address,
                mobile_number: mobile,
                prop_price: price,
                prop_description: description,
                prop_no_of_beds: beds,
                prop_no_of_bath: bathroom,
                prop_sqrt: sqrft,
                prop_rating: rate,
                prop_capacity: plimit,
                prop_city: city,
            });
            setDefImg(image)

            setPosition({
                latitude,
                longitude: longtitude,
            });
        } else {
            resetForm();
        }
    }, [isEditSelectedProperty, editSelectedProperty])

    useEffect(() => {
        const fetchFacilityData = async () => {
            try {
                await dispatch(fetchPropertyFacilities());
            } catch (error) {
                console.error("Error dispatching fetchPropertyFacilities:", error);
            }
        };

        fetchFacilityData();
    }, [dispatch]);

    useEffect(() => {
        if (facilitys && facilitys.facilitylist) {
            setPropertyFacilityData(facilitys.facilitylist);
        }
    }, [facilitys]);

    useEffect(() => {
        if (isEditSelectedProperty) {
            const facilityNames = editSelectedProperty.facility_select.split(',')?.map(name => name.trim());
            const facilityMap = propertyFacilityData.reduce((map, fac) => {
                map[fac.title] = fac.id;
                return map;
            }, {});

            const mappedIds = facilityNames?.map(name => facilityMap[name]);

            setMappedFacilities(mappedIds);
            setCheckedFacilities(mappedIds);
        }
    }, [isEditSelectedProperty, editSelectedProperty, propertyFacilityData]);

    useEffect(() => {
        if (data) {
            setSnackMsg(data?.ResponseMsg)
        }
    }, [data])


    useEffect(() => {
        const fetchCountryDataAsync = async () => {
            try {
                await dispatch(fetchCountryData({ uid: isUserId || '0' }));
            } catch (error) {
                console.error("Error fetching country data:", error);
            }
        };

        fetchCountryDataAsync();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (countryList?.CountryData) {
            setCountryData(countryList.CountryData);
            setCountryListData(countryList.CountryData);
        }
    }, [countryList, setCountryData, setCountryListData]);

    const resetForm = () => {
        setSelectedProperty('');
        setSelectedCountry('');
        setSelectedPropertyType('');
        setSelectedPropertyStatus('');
        setSelectedFile(null);
        setPropertyDetails({
            prop_title: '',
            prop_address: '',
            mobile_number: '',
            prop_price: '',
            prop_description: '',
            prop_no_of_beds: '',
            prop_no_of_bath: '',
            prop_sqrt: '',
            prop_rating: '',
            prop_capacity: '',
            prop_city: '',
        });
        setPosition({
            latitude: null,
            longitude: null,
        });
        setDefImg(null)
        setMappedFacilities([]);
        setCheckedFacilities([])
    };

    const validatePropInputs = () => {
        const newErrrors = {status:'',country_id:'',pbuysell:'',title:'',address:'',description:'',facilitys:'',ptype:'',beds:'',bathroom:'',sqft:'',rate:'',latitude:'',longitude:'',mobile:'',price:'',img:'',prop_id:''}
        let isValid = false

        if(!isUserId || !selectedPropertyStatus || !propertyDetails.prop_capacity || !selectedCountry || !selectedProperty || propertyDetails.prop_title || !propertyDetails.prop_address || !propertyDetails.prop_description || !propertyDetails.prop_city || !checkedFacilities || !selectedPropertyType || !propertyDetails.prop_no_of_beds || !propertyDetails.prop_sqrt || !propertyDetails.prop_rating || !position.latitude || !position.longitude || !propertyDetails.mobile_number || !propertyDetails.prop_price || !selectedFile ){
            setShowSnackbar(true)
            setSnackMsg('all field are required!')
            isValid = false
        }
    }

    const handleAddProperty = async (event) => {
        event.preventDefault();
        const endpoint = isEditSelectedProperty ? 'u_property_edit.php' : 'u_property_add.php';

        // if (!validatePropInputs()) return;
        const apiData = {
            uid: isUserId,
            status: selectedPropertyStatus,
            plimit: propertyDetails.prop_capacity,
            country_id: selectedCountry,
            pbuysell: selectedProperty,
            title: propertyDetails.prop_title,
            address: propertyDetails.prop_address,
            description: propertyDetails.prop_description,
            ccount: propertyDetails.prop_city,
            facility: checkedFacilities.toString(),
            ptype: selectedPropertyType,
            beds: propertyDetails.prop_no_of_beds,
            bathroom: propertyDetails.prop_no_of_bath,
            sqft: propertyDetails.prop_sqrt,
            rate: propertyDetails.prop_rating,
            latitude: position.latitude,
            longitude: position.longitude,
            mobile: propertyDetails.mobile_number,
            price: propertyDetails.prop_price,
            img: selectedFile,
            prop_id: isEditSelectedProperty ? editSelectedProperty?.id : undefined
        };

        try {
            const response = await dispatch(fetchData(endpoint, apiData));
            setShowSnackbar(true);
            resetForm()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleGetLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPropertyDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFile(reader.result.split(',')[1]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelect = (setter) => (event) => {
        setter(event.target.getAttribute('data-value'));
    };

    const handleCheckBox = (id) => {
        setCheckedFacilities(prev => {
            const isChecked = prev.includes(id);
            return isChecked ? prev.filter(item => item !== id) : [...prev, id];
        });
    };

    const handleCloseSnackbar = () => setShowSnackbar(false);
    // const snackMessage = data?.ResponseMsg;

    const handleItemSell = async () => {
        try {
            const formData = new FormData();
            formData.append('avatar', selectedFile);

            const response = await axios.post(`${baseUrl}u_sale_prop.php`, {
                uid: isUserId,
                prop_id: editSelectedProperty?.id
            });
            setShowSnackbar(true)
            setSnackMsg(response?.data?.ResponseMsg)
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    return (
        <>

            <div className="wg-box pl-44 mb-20">
                <form className="form-basic-information flex gap30 flex-column">
                    {editSelectedProperty?.buyorrent == '2' && editSelectedProperty?.is_sell == '0' && isEditSelectedProperty &&
                        <div>
                            <a className="tf-button-primary style-black active bg-danger" onClick={handleItemSell} style={{ float: 'right' }}> {t('SOLD OUT')}</a>
                        </div>
                    }
                    <div className='cols'>
                        <SelectField
                            label="Property Type"
                            options={[
                                { value: '2', label: 'Buy' },
                                { value: '1', label: 'Rent' }
                            ]}
                            selectedValue={selectedProperty}
                            onSelect={handleSelect(setSelectedProperty)}
                        />

                        <SelectField
                            label="Country"
                            options={countryData?.map(item => ({ value: item?.id, label: item?.title }))}
                            selectedValue={selectedCountry}
                            onSelect={handleSelect(setSelectedCountry)}
                        />
                    </div>

                    <TextField
                        label="Property Title"
                        name="prop_title"
                        value={propertyDetails.prop_title}
                        onChange={handleChange}
                    />

                    <TextAreaField
                        label="Property Description"
                        name="prop_description"
                        value={propertyDetails.prop_description}
                        onChange={handleChange}
                    />
                </form>
            </div>

            <div className="wg-box pl-44 mb-20">
                <h4>{t('Additional')}</h4>
                <form className="form-additional flex gap30 flex-column">
                    <SelectField
                        label="Property Type"
                        options={propertyType?.map(item => ({ value: item?.id, label: item?.title }))}
                        selectedValue={selectedPropertyType}
                        onSelect={handleSelect(setSelectedPropertyType)}
                    />
                    <div className="cols cols-two">
                        <TextField
                            label="Address"
                            name="prop_address"
                            value={propertyDetails.prop_address}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Mobile Number"
                            name="mobile_number"
                            value={propertyDetails.mobile_number}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Property Price"
                            name="prop_price"
                            value={propertyDetails.prop_price}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="cols cols-two">
                        <TextField
                            label="Total Beds"
                            name="prop_no_of_beds"
                            value={propertyDetails.prop_no_of_beds}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Total Bathrooms"
                            name="prop_no_of_bath"
                            value={propertyDetails.prop_no_of_bath}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Property Sqft"
                            name="prop_sqrt"
                            value={propertyDetails.prop_sqrt}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Property Rating"
                            name="prop_rating"
                            value={propertyDetails.prop_rating}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="cols cols-two">
                        <TextField
                            label="Capacity"
                            name="prop_capacity"
                            value={propertyDetails.prop_capacity}
                            onChange={handleChange}
                        />
                        <TextField
                            label="City, Country"
                            name="prop_city"
                            value={propertyDetails.prop_city}
                            onChange={handleChange}
                        />
                    </div>
                </form>
            </div>

            <div className="wg-box pl-44 mb-20">
                <h4>{t('Select Property Facility')}</h4>
                <form className="form-amenities">
                    <ul className="grid-checkbox">
                        {propertyFacilityData?.map(item => (
                            <li className="checkbox-item" key={item?.id}>
                                <label>
                                    <p>{item?.title}</p>
                                    <input
                                        type="checkbox"
                                        onChange={() => handleCheckBox(item?.id)}
                                        checked={checkedFacilities.includes(item?.id)}
                                    />
                                    <span className="btn-checkbox"></span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </form>
            </div>

            <div className="wg-box pl-44 mb-20">
                <h4>{t('Status')}</h4>
                <form className="form-price flex gap30 flex-column">
                    <div className="cols">
                        <TextField
                            label="Latitude"
                            value={position.latitude || ''}
                            disabled
                        />
                        <TextField
                            label="Longitude"
                            value={position.longitude || ''}
                            disabled
                        />
                    </div>

                    <div className='d-flex p-0 m-0 pointer' onClick={handleGetLocation}>
                        <IconLocation className='float-start p-1 bg-primary m-2 rounded-circle text-white' />
                        <h6 className='p-2'>{t('Click for Current Location')}</h6>
                    </div>

                    <div className="cols">
                        <FileUpload
                            defImg={defImg}
                            imageBaseUrl={imageBaseUrl}
                            selectedFile={selectedFile}
                            onFileChange={handleImageChange}
                        />
                        <SelectField
                            label="Property Status"
                            options={[
                                { value: '0', label: 'Publish' },
                                { value: '1', label: 'UnPublish' }
                            ]}
                            selectedValue={selectedPropertyStatus}
                            onSelect={handleSelect(setSelectedPropertyStatus)}
                        />
                    </div>
                    {editSelectedProperty?.is_sell == '1' ? null : (
                        <div className="button-submit mt-10">
                            <button className="tf-button-primary" onClick={handleAddProperty}>
                                {t('Add Property')} <i className="icon-arrow-right-add"></i>
                            </button>
                        </div>
                    )}
                </form>
            </div>

            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />}
        </>
    );
};

export default AddPropartyForm;
